import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { OrganisationDataDummy } from './organisation.dummy'
import { Toast } from '@components/toast/toast.component'
import { IOrganisation } from './organisation.interface'

export const OrganisationStatusHeaders: ITabItem[] = [
    {
        label: 'Active',
        totalData: OrganisationDataDummy.filter((x) => x.status === 'active')
            .length,
        key: 'status',
        value: 'active',
        className: 'brand',
    },
    {
        label: 'Non Active',
        totalData: OrganisationDataDummy.filter((x) => x.status === 'nonActive')
            .length,
        key: 'status',
        value: 'nonActive',
        className: 'red',
    },
]

export const OrganisationSummaryHeaders: ITableColumn<IOrganisation>[] = [
    {
        label: 'Code',
        accessor: 'code',
    },
    {
        label: 'Full Name',
        accessor: 'fullName',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'City',
        accessor: 'city',
    },
    {
        label: 'Country',
        accessor: 'country',
    },
    {
        label: 'Email',
        accessor: 'email',
    },
    {
        label: 'Number',
        accessor: 'number',
    },
    {
        label: 'Org Type',
        accessor: 'orgType',
        customBuild: (data) => {
            let typeOrgClass: string
            const getClass = (data: string) => {
                switch (data) {
                    case 'consignee':
                        typeOrgClass = 'themes-bg-green-v3 themes-text-white'
                        break
                    case 'vip':
                        typeOrgClass = 'themes-bg-yellow-v3 themes-text-white'
                        break
                    case 'deposit':
                        typeOrgClass =
                            'themes-bg-extra-blue-v2 themes-text-white'
                        break
                    case 'carrier':
                        typeOrgClass =
                            'themes-bg-extra-red-v4 themes-text-white'
                        break
                    case 'supplier':
                        typeOrgClass =
                            'themes-bg-extra-violet-v2 themes-text-white'
                        break
                    case 'delivery agent':
                        typeOrgClass =
                            'themes-bg-extra-red-v4 themes-text-white'
                        break
                    default:
                        typeOrgClass = 'themes-bg-gray-v4 themes-text-white'
                }
                return typeOrgClass
            }
            const convertData = data as unknown as string[]
            return (
                <div className="flex gap-3">
                    {convertData?.map((item: string, idx: number) => (
                        <div
                            key={idx}
                            className={`${getClass(item)} rounded px-1`}
                        >
                            {item?.toUpperCase()}
                        </div>
                    ))}
                </div>
            )
        },
    },
    {
        label: 'Last Updated',
        accessor: 'updatedAt',
    },
]

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })
