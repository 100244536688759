/* eslint-disable no-unused-vars */
import axios from 'axios'
import { userDataSelector } from 'pages/login/login.slice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import { IBuildVersion, IConfig } from './config.interface'
import { useEffect, useState } from 'react'
import {
    currentThemeSelector,
    setClientVersion,
    setConfig,
    setTheme,
} from './config.slice'
// eslint-disable-next-line no-unused-vars
import { store } from 'store'
import { ThemeType } from 'common/common.interface'
import { useLocation, useNavigate } from 'react-router-dom'

export const useConfig = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const user: IUserAuth = useSelector(userDataSelector)
    const currentTheme: ThemeType = useSelector(currentThemeSelector)
    const [ignoredRoute] = useState(['/login', '/logout'])
    const currentLocation = location.pathname

    // axios -------------------------------------------------------------------
    if (user.token !== '') {
        axios.defaults.paramsSerializer = { indexes: null }
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.headers.common['Accept'] = '*/*'
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        axios.defaults.timeout = 500000
    }

    // use Effect ---------------------------------------------------------------
    useEffect(() => {
        starting()
    }, [])

    useEffect(() => {
        const theme = currentTheme ?? 'default'
        const body = document.body
        body.removeAttribute('class')
        body.classList.add(theme)
    }, [currentTheme])

    useEffect(() => {
        checkSession()
        // setTimeout(() => {
        // }, 800)
    }, [user])

    // function ---------------------------------------------------------------
    const checkSession = () => {
        // don't check session in login / logout , script already there
        // if user direct access, not from login hit keycloak url
        // if user not direct access, from login. ignore

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.hash.slice(1))
        const stateValue = params.get('state')
        const isIgnoredUrl = ignoredRoute.includes(currentLocation)

        if (!user?.token || user.token === '') {
            if (!isIgnoredUrl) {
                if (!stateValue) {
                    navigate('/login')
                }
            }
        } else if (stateValue) {
            if (stateValue !== user.keycloak?.tokenParsed.session_state) {
                navigate('/logout')
            } else {
                navigate('/dashboard')
            }
        }
    }

    const starting = async () => {
        // get config
        try {
            // get from selector
            const timestamp = Date.now()
            axios.get('/config.json?p=' + timestamp).then((data) => {
                const conf = data.data
                const cf: IConfig = {
                    ...conf,
                }
                dispatch(setConfig(cf))

                // Set Theme Color used
                if (!currentTheme) {
                    // Default Settings
                    dispatch(setTheme('bright-sky-blue-themes'))
                }
            })

            // get build version
            axios
                .get<IBuildVersion>('/build-version.json?p=' + timestamp)
                .then((data) => {
                    const buildVersion = data.data
                    const clientVersion = buildVersion.clientVersion
                    const lastBuild = buildVersion.lastBuild
                    console.info(
                        'Loglines' +
                            '\nClient Version: ' +
                            clientVersion +
                            '\nLast Build: ' +
                            lastBuild,
                    )
                    const nbv: IBuildVersion = {
                        ...buildVersion,
                    }
                    dispatch(setClientVersion(nbv))
                })
        } catch (e) {
            console.log(e)
        }
    }
}

export function getConfig(): IConfig {
    const state = store.getState()
    return state.config.config
}
