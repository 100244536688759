import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { FormikContextType, FormikProvider } from 'formik'

const CreateOrEditContactModal = ({
    modalService,
    formik,
    isCreate,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW CONTACT' : 'EDIT CONTACT'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="flex flex-col p-3 gap-3">
                        <FormInput
                            label="Contact name"
                            name="contactName"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Contact Name"
                        />
                        <FormInput
                            label="Job Tittle"
                            name="jobTitle"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Job Title"
                        />
                        <FormInput
                            label="Job Category"
                            name="jobCategory"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Job Category"
                        />
                        <FormInput
                            label="Phone Number"
                            name="phoneNumber"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Phone Number"
                        />
                        <FormInput
                            label="Email"
                            name="email"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Email"
                        />
                        <FormInput
                            label="Related Organisation"
                            name="relatedOrganisation"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Related Organisation"
                        />
                        <FormInput
                            label="Born Date (Optional)"
                            name="bornDate"
                            parentDivClassName="!mb-0"
                            placeholder="Enter Born Date"
                        />
                        <FormTextArea
                            label="Working Address (Optional)"
                            name="phoneNumber"
                            placeholder="Enter Working Address"
                        />
                    </div>
                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                modalService.closeModalHandling()
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE CONTACT' : 'UPDATE CONTACT'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditContactModal
