var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useProgressionStep from "./progression-step.service";
import "./progression-step.style.css";
var ProgressionStep = function (_a) {
    var steps = _a.steps, onChange = _a.onChange, selectedItem = _a.selectedItem, _b = _a.complatedStep, complatedStep = _b === void 0 ? false : _b;
    var _c = useProgressionStep(steps, selectedItem), labels = _c.labels, activeIndex = _c.activeIndex, setActiveIndex = _c.setActiveIndex;
    return (_jsx(_Fragment, { children: steps === null || steps === void 0 ? void 0 : steps.map(function (step, idx) {
            // if current step is not first then the step before is active
            // based on the latest design
            var isActive = activeIndex === idx;
            if (idx < activeIndex) {
                isActive = true;
            }
            var classActive = !isActive
                ? "themes-text-gray-v3"
                : "themes-text-brand-v5 themes-border-brand-v5 cursor-pointer";
            return (_jsxs("div", __assign({ className: "flex justify-center gap-2 ".concat(classActive), onClick: function () {
                    if (complatedStep || isActive) {
                        setActiveIndex(idx);
                        onChange(step);
                    }
                } }, { children: [idx === 0 ? ("") : isActive ? (_jsx("div", __assign({ className: "w-28 mt-5 -mx-16" }, { children: _jsx("div", { className: " border-b-2 themes-border-brand-v5" }) }))) : (_jsx("div", __assign({ className: "w-28 mt-5 -mx-16 sm:w-[30px]" }, { children: _jsx("div", { className: " border-b-2 themes-border-gray-v3" }) }))), _jsx("div", __assign({ className: "flex flex-col gap-2 justify-center items-center w-48" }, { children: _jsxs(_Fragment, { children: [_jsx("i", __assign({ className: "\n                      ".concat(step.icon, "\n                      ").concat(isActive ? "themes-border-brand-v5" : "", " \n                      text-size-M sm:text-[12px] not-italic p-1 flex justify-center aspect-square border-2  \n                      rounded-full w-10 items-center\n                  ") }, { children: step.textIcon })), _jsx("span", __assign({ className: "text-size-S font-normal sm:text-[12px]" }, { children: labels[idx] }))] }) }))] }), idx));
        }) }));
};
export default ProgressionStep;
