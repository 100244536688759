import { ILoginValidation } from 'form-validation/login.validation'
import { IUser } from './interface/user.interface'
import json from './static-data/dummy-user.json'

export const postLogin = async (payload: ILoginValidation) => {}

export const getUserData = (status: string): IUser[] => {
    try {
        const response = json as unknown as IUser[]
        return response.filter((val) => val.status === status)
    } catch (error: any) {
        throw error.message
    }
}

// export const changePassword = async (payload: any) => {
//     try {
//         await put<IResponseData<any>, IChangePasswordValidation>({
//             endpoint: endpoints.common.staff_changePassword,
//             payload,
//         })
//         Toast({
//             header: 'Success',
//             message: 'Success Update Password',
//             type: 'success',
//         })
//         return 'success'
//     } catch (error: any) {
//         Toast({
//             header: 'Failed!',
//             message: error?.response?.data ?? 'Failed Update Password',
//             type: 'error',
//         })
//         return error.response.data as string
//     }
// }

// export const updateUser = async (id: string, payload: any) => {
//     try {
//         const execute = await put<IResponseData<any>, ILoginValidation>({
//             endpoint: endpoints.common.staff_update + '/' + id,
//             payload,
//         })
//         return execute
//     } catch (error: any) {
//         return error.response.data
//     }
// }

// export const getUserDetail = async (uuid: string) => {
//     try {
//         const response = await fetch<IResponseData<IUser>, { uuid: string }>({
//             endpoint: endpoints.common.staff_get + '/' + uuid,
//             params: { uuid },
//         })
//         return response
//     } catch (error: any) {
//         return error.response.data
//     }
// }

// export const suspendUser = async (uuid: string) => {
//     try {
//         const response = await put<IResponseData<any>, { uuid: string }>({
//             endpoint: endpoints.common.staff_delete + '/' + uuid,
//             params: { uuid },
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const unSuspendUser = async (uuid: string) => {
//     try {
//         const response = await put<IResponseData<any>, { uuid: string }>({
//             endpoint: endpoints.common.staff_unsuspend + '/' + uuid,
//             params: { uuid },
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const updateModuleUser = async (uuid: string, payload: IFRoleModule) => {
//     try {
//         const response = await put<IResponseData<any>, IFRoleModule>({
//             endpoint: endpoints.common.staff_updateModule + '/' + uuid,
//             payload: payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const forgotPassword = async (payload: IForgotPassword) => {
//     try {
//         const response = await post<IResponseData<any>, IForgotPassword>({
//             endpoint: endpoints.common.staff_forgotPassword,
//             params: payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const postResetPassword = async (payload: IResetPasswordValidation) => {
//     try {
//         const response = await post<
//             IResponseData<any>,
//             IResetPasswordValidation
//         >({
//             endpoint: endpoints.common.staff_resetPassword,
//             payload,
//         })
//         return response
//     } catch (error: any) {
//         throw error.response.data
//     }
// }

// export const getUserCurrent = async () => {
//     try {
//         const response = await fetch<IResponseData<any>, IUserAccessFilter>({
//             endpoint: endpoints.common.staff_current,
//         })
//         return response
//     } catch (error: any) {
//         console.log(error?.message)
//     }
// }

// export const getUserActivityLogs = async (id: string) => {
//     const url: string = `Staff/${id}/activities`
//     try {
//         const response = await fetch<IResponseData<IUserActivities[]>, null>({
//             endpoint: url,
//         })
//         return response
//     } catch (error: any) {
//         console.error(error)
//         const err = error as IResponseData<IUserActivities[]>
//         err.isSuccess = false
//         return err
//     }
// }
