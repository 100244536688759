import moment from 'moment'
import * as Yup from 'yup'

export interface IFormBooking {
    bookingNo: string
    createdBy: string
    creationDate: string
    status: string
    agent: string
    notify: string
    transportMode: string
    etd: string
    eta: string
    originLocation: {
        origin: string
        country: string
        state: string
    }
    destinationLocation: {
        destination: string
        country: string
        state: string
    }
    consignorPickUpAddress: {
        consignor: string
        address: string
        address1: string
    }
    consigneeDeliveryAddress: {
        consignee: string
        address: string
        address1: string
    }
    inCludePickUp: boolean
    withDelivery: boolean
    contactAgent: {
        agent: string
        agentEmail: string
        agentPhoneNumber: string
    }
    contactsNotify: {
        notify: string
        notifyEmail: string
        notifyPhoneNumber: string
    }[]
}

export const formBookingValidation = Yup.object({
    etd: Yup.string().required('ETD is required'),
})

export const formBookingInitialValue: IFormBooking = {
    bookingNo: 'B121254154',
    createdBy: 'Admin A',
    creationDate: moment().format('DD/MM/YYYY'),
    agent: '',
    notify: '',
    status: 'New',
    transportMode: '',
    etd: '',
    eta: '',
    originLocation: {
        origin: '',
        country: '',
        state: '',
    },
    destinationLocation: {
        destination: '',
        country: '',
        state: '',
    },
    consignorPickUpAddress: {
        consignor: '',
        address: '',
        address1: '',
    },
    consigneeDeliveryAddress: {
        consignee: '',
        address: '',
        address1: '',
    },
    inCludePickUp: false,
    withDelivery: false,
    contactAgent: {
        agent: '',
        agentEmail: '',
        agentPhoneNumber: '',
    },
    contactsNotify: [
        {
            notify: '',
            notifyEmail: '',
            notifyPhoneNumber: '',
        },
    ],
}
