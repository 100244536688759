import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IRoute } from 'common/common.interface'
import { useAppDispatch, useAppSelector } from 'store'
import {
    closeMenuHandler,
    openMenuHandler,
    getOpenMenu,
    closeSideModalHandler,
} from 'common/common.slice'
import Menu from './menu/menu.component'
import './layout.style.css'
import { clientVersionSelector } from 'config/config.slice'

// icons & logos
import { ReactComponent as CollapseIcon } from '@assets/img/icons/loglines_icon.svg'
import { ReactComponent as Icon } from '@assets/img/icons/loglines_logo.svg'
import { loglinesRoutes } from 'common/routes/loglines.routes'

const Sidebar: React.FC = () => {
    const nav = useNavigate()
    const dispatch = useAppDispatch()
    const isExpand = useAppSelector(getOpenMenu)
    const clientVersion = useAppSelector(clientVersionSelector)
    const [logoHiddenClass, setLogoHiddenClass] = useState<string>('')
    const [smallLogoHiddenClass, setSmallLogoHiddenClass] = useState<string>('')
    const expandHandler = () =>
        isExpand ? closeMenuHandler() : openMenuHandler()
    const widthClass = isExpand
        ? 'sm:w-[100vw] w-[250px] '
        : 'sm:w-0 sm:ml-[-80px]  w-[82px] '
    const mobilePadding = isExpand ? 'sm:p-[16px]' : 'sm:py-[16px]'
    const sidebarLogo = isExpand
        ? 'ri-arrow-left-double-line'
        : 'ri-arrow-right-double-line'
    useEffect(() => {
        // smooth transition
        let delay = 0
        if (isExpand) {
            delay = 100
        }
        setTimeout(() => {
            setLogoHiddenClass(isExpand ? '!opacity-100' : 'opacity-0 hide')
            setSmallLogoHiddenClass(
                !isExpand ? '!opacity-100' : 'opacity-0 hide',
            )
        }, delay)
    }, [isExpand])

    const actionHandler = (path: string) => {
        dispatch(closeSideModalHandler())
        nav(path)
    }

    return (
        <div className={`themes-sidebar order-1 sm:border-t mobile-version`}>
            {/* Logo */}
            <div className="sm:hidden themes-sidebar-header min-w-[82px] h-[4rem] flex w-full justify-between items-center">
                {!isExpand ? (
                    <div className="w-full flex justify-center">
                        <CollapseIcon
                            onClick={() => dispatch(expandHandler())}
                            className={`h-[40px] themes-sidebar-icon-collapse opacity-0 logo-small-animation cursor-pointer ${smallLogoHiddenClass}`}
                        />
                    </div>
                ) : (
                    <></>
                )}

                <div
                    className={`logo-animation w-full flex justify-between items-center ${logoHiddenClass} px-5 `}
                    style={{ display: isExpand ? '' : 'none' }}
                >
                    <Icon
                        width={80}
                        height={40}
                        onClick={() => dispatch(expandHandler())}
                        className={`themes-sidebar-icon`}
                    />

                    <i
                        onClick={() => dispatch(expandHandler())}
                        className={`${sidebarLogo}  ease-in-out cursor-pointer z-50 text-[1.5rem] themes-sidebar-collapse-button `}
                    />
                </div>
            </div>

            <div className="mx-5 border-b sm:hidden "> </div>

            {/* Routes */}
            <div
                className={`box-shadow flex flex-col justify-between top-0 left-0 transition-all ease-in-out duration-[0.5s]  sm:overflow-hidden border-[#f6f7fb] h-[calc(100vh-4rem)] transform shadow-sm ${widthClass} ${mobilePadding} px-5 pt-2`}
            >
                <div className="flex-auto flex flex-col h-[calc(100vh-6rem)] overflow-hidden">
                    {loglinesRoutes
                        ?.filter((route) => route.show === true)
                        .map((route: IRoute, idx: number) => (
                            <Menu
                                {...route}
                                showName={isExpand}
                                key={idx}
                                openSideBar={() => dispatch(openMenuHandler())}
                                action={actionHandler}
                            />
                        ))}
                </div>

                <div className="flex-1 py-4 px-0 text-center text-size-XS">
                    V {clientVersion?.clientVersion}
                </div>
            </div>
        </div>
    )
}

export default Sidebar
