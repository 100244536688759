import { IRoute } from 'common/common.interface'
import Settings from 'pages/settings/settings.component'
import { DashboardInProgress } from 'pages/work-in-progress/work-in-progress.component'
import Bookings from 'pages/bookings/bookings.component'
import CreateOrganisation from 'pages/organisation/create-organisation/create-organisation.component'
import ContactList from 'pages/contact-list/contact-list.component'
import OrganisationDetail from 'pages/organisation/organisation-details/organisation-details.component'
import Organisation from 'pages/organisation/organisation.component'
import Shipments from 'pages/shipments/shipments.component'
import UserAccess from 'pages/user-acces/user-access.component'
import UserAccessDetail from 'pages/user-acces/user-access-detail/user-access-detail.component'
import UserAccessForm from 'pages/user-acces/user-access-form/user-access-form.component'
import Quotes from 'pages/quotes/quotes.component'
import FormBooking from 'pages/bookings/form-booking/form-booking.component'
import Experiment from 'pages/experiment/experiment.component'
import FormQuote from 'pages/quotes/form/form.component'

export const loglinesRoutes: IRoute[] = [
    {
        path: '/',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: false,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/dashboard',
        text: 'Dashboard',
        Content: DashboardInProgress,
        show: true,
        icon: 'ri-dashboard-line',
        textTranslationCode: 'menu.dashboard',
    },
    {
        path: '/quotes',
        text: 'Quotes',
        Content: Quotes,
        show: true,
        icon: 'ri-file-paper-line',
        textTranslationCode: 'menu.quotes',
    },
    {
        path: '/quote-details/:id',
        text: 'Detail Quote',
        Content: () => FormQuote({ isDetailPage: true }),
        textTranslationCode: 'quotes.quote_details',
    },
    {
        path: '/new-quote',
        text: 'New Quote',
        Content: () => FormQuote({ isDetailPage: false }),
        textTranslationCode: 'menu.quotes_new',
    },
    {
        path: 'forwarding',
        text: 'Forwarding',
        show: true,
        icon: 'ri-truck-line',
        expandable: true,
        textTranslationCode: 'menu.forwarding',
        sub: [
            {
                path: '/bookings',
                text: 'Bookings',
                Content: Bookings,
                parentId: '4',
                show: true,
                textTranslationCode: 'menu.bookings',
            },
            {
                path: '/shipments',
                text: 'Shipments',
                Content: Shipments,
                show: true,
                textTranslationCode: 'menu.shipments',
            },
        ],
    },
    {
        path: 'create-booking',
        text: 'Create Booking',
        Content: () => FormBooking({ isPageDetails: false }),
        textTranslationCode: 'menu.bookings_create',
    },
    {
        path: 'booking-details',
        text: 'Booking',
        Content: () => FormBooking({ isPageDetails: true }),
        textTranslationCode: 'menu.bookings_details',
    },
    {
        path: 'organisation',
        text: 'Organisation',
        show: true,
        icon: 'ri-contacts-book-2-line',
        textTranslationCode: 'menu.organisation',
        expandable: true,
        sub: [
            {
                path: '/organisation',
                text: 'All Organisation',
                textTranslationCode: 'menu.organisation',
                Content: Organisation,
                show: true,
            },
            {
                path: '/contact-list',
                text: 'Contact List',
                textTranslationCode: 'menu.contact_list',
                Content: ContactList,
                show: true,
            },
        ],
    },
    {
        path: 'organisation-detail',
        text: 'Organisation Details',
        Content: OrganisationDetail,
        textTranslationCode: 'menu.organisation_detail',
    },
    {
        path: 'create-organisation',
        text: 'Create Organisation ',
        Content: CreateOrganisation,
        textTranslationCode: 'menu.organisation_create',
    },
    {
        path: '/manage-users',
        text: 'Manage User',
        textTranslationCode: 'menu.manage_user',
        Content: UserAccess,
        show: true,
        icon: 'ri-user-line',
    },
    {
        path: '/manage-users/details/:id',
        text: 'User Details',
        textTranslationCode: 'menu.manage_user_details',
        Content: UserAccessDetail,
    },
    // {
    //     path: '/profile',
    //     text: 'Profile',
    //     Content: () => ManageUserForm({ isNew: false, isProfilePage: true }),
    // },
    {
        path: 'manage-users/create/',
        text: 'Create User',
        Content: () => UserAccessForm({ isNew: true, isProfilePage: false }),
    },
    {
        path: 'manage-users/update/:id',
        text: 'Update User',
        Content: () => UserAccessForm({ isNew: false, isProfilePage: false }),
    },
    {
        path: '/settings',
        text: 'Settings',
        Content: Settings,
        show: true,
        icon: 'ri-settings-line',
        textTranslationCode: 'menu.settings',
    },
    {
        path: 'experiment',
        text: 'Experiment Page',
        Content: Experiment,
    },
]
