import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IQuotation } from 'repository/interface/quotations.interface'
import { tabItemsStatic } from './quotes.static'

interface IQuotesSlice {
    data: IQuotation[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItems: ITabItem[]
    tabItemSelected: ITabItem
    tabItemSelectedHelper: string
    isLoading: boolean
}
export const initialState: IQuotesSlice = {
    data: [],
    tabItemSelected: tabItemsStatic[0],
    tabItems: tabItemsStatic,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    tabItemSelectedHelper: tabItemsStatic[0].value,
    fetchParams: {},
    exportFetchParams: {},
    isLoading: false,
}

const quotesSlice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<IQuotation[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: { ...fetchParam, transport: data.value },
                tabItemSelected: data,
            }
        },
        setSelectedTabItemHelper(state, action: PayloadAction<string>) {
            const data = action.payload
            return {
                ...state,
                tabItemSelectedHelper: data,
            }
        },
    },
})

// these all the variables used for selector
export const quotesFetchParamSelector = (state: RootState) =>
    state.quotesLimajari.fetchParams || {}
export const quotesExportFetchParamSelector = (state: RootState) =>
    state.quotesLimajari.exportFetchParams || {}
export const quotesDataSelector = (state: RootState) =>
    state.quotesLimajari.data || []
export const quotesMetaSelector = (state: RootState) =>
    state.quotesLimajari.meta || {}
export const quotesTabItemsSelector = (state: RootState) =>
    state.quotesLimajari.tabItems
export const quotesSelectedTabItemSelector = (state: RootState) =>
    state.quotesLimajari.tabItemSelected
export const quotesSelectedTabItemHelperSelector = (state: RootState) =>
    state.quotesLimajari.tabItemSelectedHelper
export const quotesIsLoading = (state: RootState) =>
    state.quotesLimajari.isLoading

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setSelectedTabItemHelper,
    setIsLoading,
} = quotesSlice.actions

export default quotesSlice.reducer
