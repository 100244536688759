/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import { contactListHeaders } from '../organisation-details.static'
import { IContactList } from '../organisation-details.interface'
import Input from '@components/input/input.component'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { IUseModal } from '@components/modal/modal.service'
import RemoveModalAddress from '../components/remove-address-modal.component'

const ContactList = ({
    data,
    detailsData,
    setDetailsData,
    modalService,
    setIsCreateAddress,
    removeModalService,
    removeData,
    addContactModalService,
}: {
    data: IContactList[]
    detailsData: IContactList
    setDetailsData: (data: IContactList) => void
    modalService: IUseModal
    setIsCreateAddress: (data: boolean) => void
    removeModalService: IUseModal
    removeData: () => void
    addContactModalService: IUseModal
}) => {
    return (
        <div className="flex gap-x-2">
            <div className="w-3/4 h-[calc(100vh-15.5rem)]">
                <SimpleTable<IContactList>
                    headers={contactListHeaders}
                    data={data}
                    addRowLabel="Add Contact"
                    isAddRow={true}
                    variant="gray"
                    onRowClick={(data) => setDetailsData(data)}
                    customBuildParam4={() => ({
                        // set details
                        func1: () => {
                            setIsCreateAddress(false)
                            modalService.openModalHandling()
                        },
                        func2: () => {
                            removeModalService.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        addContactModalService.openModalHandling()
                    }}
                />
            </div>
            <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                <div className="p-3">Contact Details</div>
                <div className="border-b"></div>
                <div className="p-3 overflow-auto">
                    <div className="flex flex-col gap-3">
                        <ToggleSwitch
                            initialOn={detailsData.active}
                            label="Active"
                            disabled={true}
                        />
                        <Input
                            label="Contact Name"
                            value={detailsData.contactName}
                            disabled={true}
                        />
                        <Input
                            label="Job Tittle"
                            value={detailsData.jobTitle}
                            disabled={true}
                        />
                        <Input
                            label="Job Category"
                            value={detailsData.jobCategory}
                            disabled={true}
                        />
                        <Input
                            label="Phone Number"
                            value={detailsData.phoneNumber}
                            disabled={true}
                        />
                        <Input
                            label="Email"
                            value={detailsData.email}
                            disabled={true}
                        />
                        <Input label="Related Organisation" disabled={true} />
                        <Input
                            label="Born Date"
                            value="01/01/2023"
                            disabled={true}
                        />
                        <Input
                            label="Working Address"
                            value="Jalan Teuku Umar No. 567, Denpasar, Bali"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <RemoveModalAddress
                modalService={removeModalService}
                type="removeAddress"
                onClick={() => {
                    removeData()
                }}
                data={detailsData.contactName}
            />

            {/* contactList: ContactList({
                data: contactListData,
                detailsData: contactListDetails,
                setDetailsData: (data) => setContactListDetails(data),
                modalService: createOrEditContactModal,
                removeModalService: removeContactModalService,
                removeData: removeContactList,
                setIsCreateAddress: (data: boolean) => setIsCreateAddress(data),
            }), */}
        </div>
    )
}

export default ContactList
