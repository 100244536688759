import Button from '@components/button/button.component'
import useFormBooking from './form-booking.service'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import TransportModeCard from './components/transport-mode-card.component'
import { formBookingTransportMode } from './form-booking.static'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import Checkbox from '@components/checkbox/checkbox.component'
import { contactNotifyOptionsDummy } from './form-booking.dummy'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const FormBooking = ({ isPageDetails }: { isPageDetails: boolean }) => {
    const {
        formik,
        bookingStatusOption,
        allModal,
        headerButton,
        footerButton,
        formNotifyadditionalClick,
        setTransportModeItem,
        addMoreNotify,
        removeRowNotify,
    } = useFormBooking({ isPageDetails })

    return (
        <div className="container-global content-full-height flex">
            <FormikProvider value={formik}>
                <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                    <div className="flex items-center justify-between p-3">
                        <div>
                            {isPageDetails
                                ? `BOOKING ID ${formik.values.bookingNo}`
                                : 'CREATE BOOKING'}
                        </div>
                        <div className="flex gap-3 justify-between">
                            {headerButton()}
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <div className="overflow-auto h-[100vh-14rem)]">
                        <div className="gap-3 p-3 grid grid-cols-4">
                            <FormInput
                                name="bookingNo"
                                label="BOOKING ID"
                                disabled
                                parentDivClassName="!mb-0"
                            />
                            <FormDropdown
                                name="createdBy"
                                label="CREATED BY"
                                options={[
                                    {
                                        label: 'Admin A',
                                        value: 'Admin A',
                                    },
                                ]}
                                dropDownIndicator={true}
                                isSearchable={true}
                                disabled
                                parentDivClassName="!mb-0"
                            />
                            <FormInput
                                name="creationDate"
                                label="CREATION DATE"
                                disabled
                                parentDivClassName="!mb-0"
                            />
                            <FormDropdown
                                name="status"
                                options={bookingStatusOption}
                                dropDownIndicator={true}
                                isSearchable={true}
                                parentDivClassName="!mb-0"
                            />
                        </div>
                        <div className="p-3 flex flex-col gap-3">
                            <div className="border-b"></div>
                            <div className="grid grid-cols-2 gap-3">
                                <div className="flex flex-col gap-3">
                                    <div>Shipment Details</div>
                                    <div className="grid grid-cols-2 gap-3">
                                        <div className="col-span-2">
                                            <TransportModeCard
                                                items={formBookingTransportMode}
                                                setTransportModeItem={(item) =>
                                                    setTransportModeItem(item)
                                                }
                                            />
                                        </div>
                                        <div className="relative">
                                            <FormDatePicker
                                                name={'etd'}
                                                isRange={false}
                                                label="ETD"
                                                placeholder="ETD (Optional)"
                                            />
                                        </div>
                                        <div className="relative">
                                            <FormDatePicker
                                                name={'eta'}
                                                isRange={false}
                                                label="ETA"
                                                placeholder="ETA (Optional)"
                                            />
                                        </div>
                                        <div className="">
                                            Origin Location (Optional)
                                        </div>
                                        <div className="">
                                            Origin Location (Optional)
                                        </div>
                                        <FormDropdown
                                            name="originLocation.origin"
                                            label="ORIGIN LOCATION"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select UNLOCO"
                                            options={[
                                                {
                                                    label: 'IIDPS',
                                                    value: 'IIDPS',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                            additionalOnClick={() => {
                                                formik.setFieldValue(
                                                    'originLocation.country',
                                                    'INDONESIA',
                                                )
                                                formik.setFieldValue(
                                                    'originLocation.state',
                                                    'BALI',
                                                )
                                            }}
                                        />
                                        <FormDropdown
                                            name="destinationLocation.destination"
                                            label="DESTINATION LOCATION"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select Destination Location"
                                            options={[
                                                {
                                                    label: 'FRLEH',
                                                    value: 'FRLEH',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                            additionalOnClick={() => {
                                                formik.setFieldValue(
                                                    'destinationLocation.country',
                                                    'FRANCE',
                                                )
                                                formik.setFieldValue(
                                                    'destinationLocation.state',
                                                    'LE HARVE',
                                                )
                                            }}
                                        />
                                        <div className="col-span-2 grid grid-cols-4 gap-3">
                                            <FormInput
                                                name="originLocation.country"
                                                label="COUNTRY/REGION"
                                                parentDivClassName="!mb-0"
                                                disabled
                                            />
                                            <FormInput
                                                name="originLocation.state"
                                                label="STATE"
                                                parentDivClassName="!mb-0"
                                                disabled
                                            />
                                            <FormInput
                                                name="destinationLocation.country"
                                                label="COUNTRY/REGION"
                                                parentDivClassName="!mb-0"
                                                disabled
                                            />
                                            <FormInput
                                                name="destinationLocation.state"
                                                label="STATE"
                                                parentDivClassName="!mb-0"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="border-b"></div>
                                    <div className="grid grid-cols-2 gap-3">
                                        <div>Consignor Pick Up Address</div>
                                        <div>Consignee Delivery Address</div>
                                        <FormDropdown
                                            name="consignorPickUpAddress.consignor"
                                            label="CONSIGNOR"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select Consignor"
                                            options={[
                                                {
                                                    label: 'BATIK BALI STORE',
                                                    value: 'BATIK BALI STORE',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                        />
                                        <FormDropdown
                                            name="consigneeDeliveryAddress.consignee"
                                            label="CONSIGNEE"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select Consignee"
                                            options={[
                                                {
                                                    label: 'RISDIAN ISBINTARA',
                                                    value: 'RISDIAN ISBINTARA',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                        />
                                        <FormDropdown
                                            name="consignorPickUpAddress.address"
                                            label="ADDRESS"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select Address"
                                            options={[
                                                {
                                                    label: 'BATIK BALI RENON',
                                                    value: 'BATIK BALI RENON',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                            additionalOnClick={() => {
                                                formik.setFieldValue(
                                                    'consignorPickUpAddress.address1',
                                                    'Jalan Ahmad Dahlan No. 567, Denpasar, Bali',
                                                )
                                            }}
                                        />
                                        <FormDropdown
                                            name="consigneeDeliveryAddress.address"
                                            label="ADDRESS"
                                            parentDivClassName="!mb-0"
                                            placeholder="Select Address"
                                            options={[
                                                {
                                                    label: 'SAN FRANSISCO 123',
                                                    value: 'SAN FRANSISCO 123',
                                                },
                                            ]}
                                            dropDownIndicator={true}
                                            isSearchable={true}
                                            additionalOnClick={() => {
                                                formik.setFieldValue(
                                                    'consigneeDeliveryAddress.address1',
                                                    '123 San Fransisco Street, 12345, Le Harv e',
                                                )
                                            }}
                                        />
                                        <FormTextArea
                                            name={
                                                'consignorPickUpAddress.address1'
                                            }
                                            label="ADDRESS 1"
                                            disabled
                                        />
                                        <FormTextArea
                                            name={
                                                'consigneeDeliveryAddress.address1'
                                            }
                                            label="ADDRESS 1"
                                            disabled
                                        />
                                        <div className="flex gap-3 items-center">
                                            <Checkbox onChecked={() => {}} />
                                            <div>Include Pick Up</div>
                                        </div>
                                        <div className="flex gap-3 items-center">
                                            <Checkbox onChecked={() => {}} />
                                            <div>With Delivery</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-col gap-3">
                                        <div>Agent (Optional)</div>
                                        <div className="grid grid-cols-10 gap-3 mt-3">
                                            <FormDropdown
                                                name="contactAgent.agent"
                                                label="AGENT"
                                                parentDivClassName="!mb-0 col-span-5"
                                                placeholder="Select Agent"
                                                options={[
                                                    {
                                                        label: 'VIONA',
                                                        value: 'VIONA',
                                                    },
                                                ]}
                                                dropDownIndicator={true}
                                                isSearchable={true}
                                                additionalOnClick={() => {
                                                    formik.setFieldValue(
                                                        'contactAgent.agentEmail',
                                                        'viona@gmail.com',
                                                    )
                                                    formik.setFieldValue(
                                                        'contactAgent.agentPhoneNumber',
                                                        '+628123324421',
                                                    )
                                                }}
                                            />
                                            <FormInput
                                                name="contactAgent.agentEmail"
                                                parentDivClassName="!mb-0 col-span-2"
                                                placeholder="Agent Email"
                                                disabled
                                                icon="file-copy-line"
                                            />
                                            <FormInput
                                                name="contactAgent.agentPhoneNumber"
                                                parentDivClassName="!mb-0 col-span-3"
                                                placeholder="Agent Phone Number"
                                                disabled
                                                icon="file-copy-line"
                                            />
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div>Notify (Optional)</div>
                                            <Button
                                                onClick={() => {
                                                    addMoreNotify()
                                                }}
                                                label="Add More Notify"
                                                icon="ri-add-line"
                                                className="!h-[32px]"
                                                variant="brand-inverse"
                                            />
                                        </div>
                                        <div className="grid grid-cols-10 gap-3">
                                            {formik.values.contactsNotify.map(
                                                (_, idx) => {
                                                    return (
                                                        <>
                                                            <FormDropdown
                                                                name={`contactsNotify[${idx}].notify`}
                                                                label="NOTIFY"
                                                                parentDivClassName="!mb-0 col-span-5"
                                                                placeholder="Select Notify"
                                                                options={
                                                                    contactNotifyOptionsDummy
                                                                }
                                                                dropDownIndicator={
                                                                    true
                                                                }
                                                                isSearchable={
                                                                    true
                                                                }
                                                                additionalOnClick={(
                                                                    data,
                                                                ) => {
                                                                    const _data =
                                                                        data as IDropdownItem
                                                                    formNotifyadditionalClick(
                                                                        idx,
                                                                        _data?.value as string,
                                                                    )
                                                                }}
                                                            />
                                                            <FormInput
                                                                name={`contactsNotify[${idx}].notifyEmail`}
                                                                parentDivClassName="!mb-0 col-span-2"
                                                                placeholder="Notify Email"
                                                                disabled
                                                                icon="file-copy-line"
                                                            />
                                                            <div className="col-span-3 flex gap-3">
                                                                <div className="flex flex-grow">
                                                                    <FormInput
                                                                        name={`contactsNotify[${idx}].notifyPhoneNumber`}
                                                                        parentDivClassName="!mb-0 w-full"
                                                                        placeholder="Notify Phone Number"
                                                                        disabled
                                                                        icon="file-copy-line"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    onClick={() => {
                                                                        removeRowNotify(
                                                                            idx,
                                                                        )
                                                                    }}
                                                                    icon="ri-delete-bin-line"
                                                                    className={`w-btnIconWidth`}
                                                                    variant="red-inverse"
                                                                    isDisabled={
                                                                        formik
                                                                            .values
                                                                            .contactsNotify
                                                                            .length ===
                                                                        1
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                },
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <div className="p-3">{footerButton()}</div>
                </div>
            </FormikProvider>
            {allModal}
        </div>
    )
}

export default FormBooking
