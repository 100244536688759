import * as Yup from 'yup'
import { IOrganisation } from 'pages/organisation/organisation.interface'
import {
    OrganisationDataDummy,
    accountsDummy,
    allContactDummy,
} from 'pages/organisation/organisation.dummy'
import {
    IAccounts,
    IAddressList,
    IContactList,
} from 'pages/organisation/organisation-details/organisation-details.interface'

// form validation YUP for formik
export const orgDetailsValidation = Yup.object({
    code: Yup.string().required('Code required'),
})
export const addressListValidation = Yup.object({
    addressName: Yup.string().required('Address Name required'),
})
export const contactListValidation = Yup.object({
    contactName: Yup.string().required('Contact Name required'),
})
export const accountValidation = Yup.object({
    accountName: Yup.string().required('Account Name required'),
})

// form login initial data
export const orgDetailsInitialValue: IOrganisation = OrganisationDataDummy[0]
export const addressListInitialValue: IAddressList = {
    id: OrganisationDataDummy.length + 1,
    mainAddress: false,
    addressName: '',
    address1: '',
    address2: '',
    countryOrRegion: '',
    state: '',
    city: '',
    postcode: '',
    unloco: '',
    activeAddress: true,
    relatedCityOrPort: '',
}
export const contactListInitialValue: IContactList = {
    id: allContactDummy.length + 1,
    contactName: '',
    jobTitle: '',
    jobCategory: '',
    phoneNumber: '',
    email: '',
    active: true,
}
export const accountInitialValue: IAccounts = {
    id: accountsDummy.length + 1,
    accountName: '',
    addressList: '',
    routingNumber: '',
    currency: '',
    defaultAcc: true,
}
