import * as Yup from 'yup'
import { requiredMessage } from './validation-message.static'
import { ICurrency } from 'repository/interface/currency.interface'

export interface IFormServiceRate {
    code: string
    charge_code: { code: string }
    price: number
    unit: string
    qty: number
    total: number
}

export interface IFormShippingRate {
    code: string
    price: number
    priceString: string
    currencyShort: string
    unit: string
    qty: number
    total: number
    totalString: string
}

export interface IFormQuote {
    name: string
    quote_date: string
    etd: string
    doc_validity_period: string
    customer_name: string
    transport_mode: string
    container_mode: string
    origin_location: { name: string; code: string }
    destination_location: { name: string; code: string }
    currency: ICurrency
    weight: number
    weight_unit: string
    volume: number
    volume_unit: string
    shipping_rate: IFormShippingRate
    serviceTemplateName: string
    serviceTemplateCode: string
    services: IFormServiceRate[]
    notes: string
}

export const useQuoteValidation = () => {
    // Define the schema for IFormServiceRate
    const formServiceRateSchema = Yup.object().shape({
        charge_code: Yup.object().shape({
            code: Yup.string().required(requiredMessage('Charge code')),
        }),
        price: Yup.number(),
        unit: Yup.string(),
        qty: Yup.number().required(requiredMessage('Quantity')),
        total: Yup.number(),
    })

    // Define the schema for IFormShippingRate
    const formShippingRateSchema = Yup.object().shape({
        code: Yup.string().required(requiredMessage('Code')),
        price: Yup.number(),
        priceString: Yup.string(),
        currencyShort: Yup.string(),
        unit: Yup.string(),
        qty: Yup.number().required(requiredMessage('Quantity')),
        total: Yup.number(),
        totalString: Yup.string(),
    })

    // Define the schema for IFormQuote
    const formQuoteSchema = Yup.object().shape({
        name: Yup.string(),
        quote_date: Yup.string().required(requiredMessage('Start date')),
        etd: Yup.string().required(requiredMessage('ETD')),
        doc_validity_period: Yup.string().required(
            requiredMessage('Document validity period'),
        ),
        customer_name: Yup.string().required(requiredMessage('Customer name')),
        transport_mode: Yup.string().required(
            requiredMessage('Transport mode'),
        ),
        container_mode: Yup.string().required(
            requiredMessage('Container mode'),
        ),
        origin_location: Yup.object().shape({
            name: Yup.string(),
            code: Yup.string().required(
                requiredMessage('Origin location code'),
            ),
        }),
        destination_location: Yup.object().shape({
            name: Yup.string(),
            code: Yup.string().required(
                requiredMessage('Destination location code'),
            ),
        }),
        currency: Yup.object().shape({
            code: Yup.string().required(requiredMessage('Currency code')),
            name: Yup.string(),
            shortCode: Yup.string(),
        }),
        weight: Yup.number().required(requiredMessage('Weight')),
        weight_unit: Yup.string(),
        volume: Yup.number().required(requiredMessage('Volume')),
        volume_unit: Yup.string(),
        shipping_rate: formShippingRateSchema.required(
            requiredMessage('Shipping rate'),
        ),
        serviceTemplate: Yup.string(),
        services: Yup.array()
            .of(formServiceRateSchema)
            .required(requiredMessage('Please add At least one service')),
        notes: Yup.string(),
    })

    const initialValue: IFormQuote = {
        quote_date: '',
        doc_validity_period: 'A_MONTH',
        customer_name: '',
        transport_mode: '',
        container_mode: '',
        etd: '',
        origin_location: {
            name: '',
            code: '',
        },
        destination_location: {
            name: '',
            code: '',
        },
        currency: {
            code: 'CUR_1000',
            name: 'IDR Indonesia',
            short_code: 'IDR',
            created: '',
            updated: '',
            symbol: '',
            test_flag: false,
            created_by: '',
            updated_by: '',
            entity_status: '',
        },
        weight: 0,
        weight_unit: 'KILOGRAM',
        volume: 0,
        volume_unit: 'LITER',
        shipping_rate: {
            code: '',
            price: 0,
            priceString: '',
            unit: '',
            qty: 0,
            total: 0,
            currencyShort: '',
            totalString: '',
        },
        services: [
            {
                charge_code: { code: '' },
                price: 0,
                unit: '',
                qty: 0,
                total: 0,
                code: '',
            },
        ],
        notes: '',
        name: '',
        serviceTemplateName: 'Custom',
        serviceTemplateCode: '',
    }

    return {
        formQuoteSchema,
        initialValue,
    }
}

export default useQuoteValidation
