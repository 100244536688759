import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchChargeCode,
    fetchContainerModes,
    fetchContainerTypes,
    fetchCurrency,
    fetchServiceRates,
    fetchShippingRates,
    fetchTransportMode,
    fetchUnlocos,
    fetchVolumeUnit,
    fetchWeightUnit,
} from 'repository/dropdown.repository'
import { ICurrency } from 'repository/interface/currency.interface'
import {
    dropdownAllContainerModeSelector,
    dropdownChargeCodesSelector,
    dropdownContainerModeSelector,
    dropdownContainerTypeSelector,
    dropdownCurrencySelector,
    dropdownServiceLengthSelector,
    dropdownServiceRatesSelector,
    dropdownServiceVolumeSelector,
    dropdownServiceWeightSelector,
    dropdownShippingRatesSelector,
    dropdownTransportModeSelector,
    dropdownUnlocodesSelector,
    setAllContainerModes,
    setChargeCodes,
    setContainerModes,
    setContainerType,
    setCurrency,
    setServiceRates,
    setShippingRates,
    setTransportMode,
    setUnlocodes,
    setVolumeUnits,
    setWeightUnits,
} from './dropdown.slice'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnit } from 'repository/interface/unit.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { IFetchParams } from 'common/common.interface'
import { IChargeCode } from 'repository/interface/charge-code.interface'

const useDropdown = () => {
    // Selector & dispatch
    const dispatch = useDispatch()
    const currencyData = useSelector(dropdownCurrencySelector)
    const containerTypeData = useSelector(dropdownContainerTypeSelector)
    const transportModesData = useSelector(dropdownTransportModeSelector)
    const shippingRatesData = useSelector(dropdownShippingRatesSelector)
    const serviceRatesData = useSelector(dropdownServiceRatesSelector)
    const weightUnitsData = useSelector(dropdownServiceWeightSelector)
    const volumeUnitsData = useSelector(dropdownServiceVolumeSelector)
    const lengthUnitsData = useSelector(dropdownServiceLengthSelector)
    const containerModeData = useSelector(dropdownContainerModeSelector)
    const allContainerModeData = useSelector(dropdownAllContainerModeSelector)
    const unlocodesData = useSelector(dropdownUnlocodesSelector)
    const chargeCodesData = useSelector(dropdownChargeCodesSelector)

    const getCurrency = async () => {
        if (currencyData.length > 0) return
        const response = await fetchCurrency()
        if (!response) return []
        const dropdown: IDropdownItem<ICurrency>[] = response.data.map((d) => {
            return {
                label: d.short_code + '\t|\t' + d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setCurrency(dropdown))
    }
    const getContainerType = async () => {
        if (containerTypeData.length > 0) return
        const response = await fetchContainerTypes()
        if (!response) return []
        const dropdown: IDropdownItem<any>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setContainerType(dropdown))
    }
    const getTransportModes = async () => {
        if (transportModesData.length > 0) return
        const response = await fetchTransportMode()
        if (!response) return []
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setTransportMode(dropdown))
    }
    const getShippingRates = async (payload: IFetchParams) => {
        const response = await fetchShippingRates(payload)
        if (!response) return []
        const dropdown: IDropdownItem<IShippingRate>[] = response.data.map(
            (d) => {
                return {
                    label: `${d.carrier.name}`,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setShippingRates(dropdown))
    }

    const getServiceRates = async () => {
        if (serviceRatesData.length > 0) return
        const response = await fetchServiceRates()
        if (!response) return []
        const dropdown: IDropdownItem<IServiceRate>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setServiceRates(dropdown))
    }

    const getChargeCode = async () => {
        if (chargeCodesData.length > 0) return
        const response = await fetchChargeCode()
        if (!response) return []
        const dropdown: IDropdownItem<IChargeCode>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setChargeCodes(dropdown))
    }

    const getWeightUnit = async () => {
        if (weightUnitsData.length > 0) return
        const response = await fetchWeightUnit()
        if (!response) return []
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setWeightUnits(dropdown))
    }

    const getVolumeUnit = async () => {
        if (volumeUnitsData.length > 0) return
        const response = await fetchVolumeUnit()
        if (!response) return []
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setVolumeUnits(dropdown))
    }

    const getLengthUnit = async () => {
        if (lengthUnitsData.length > 0) return
        const response = await fetchVolumeUnit()
        if (!response) return []
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setWeightUnits(dropdown))
    }

    const getContainerModes = async (transportMode: string) => {
        const response = await fetchContainerModes(transportMode)
        if (!response) return []
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setContainerModes(dropdown))
    }
    const getAllContainerModes = async () => {
        const response1 = await fetchContainerModes('SEA')
        const response2 = await fetchContainerModes('AIR')
        if (!response1) return
        if (!response2) return
        const dropdown1: IDropdownItem<ITransportMode>[] = response1.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        const dropdown2: IDropdownItem<ITransportMode>[] = response2.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setAllContainerModes([...dropdown1, ...dropdown2]))
    }

    const getUnlocos = async () => {
        if (unlocodesData.length > 0) return
        const response = await fetchUnlocos()
        if (!response) return []
        const dropdown: IDropdownItem<IUnlocodes>[] = response.data.map((d) => {
            return {
                label: d.code,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setUnlocodes(dropdown))
    }

    return {
        getCurrency,
        getContainerType,
        getTransportModes,
        getShippingRates,
        getServiceRates,
        getWeightUnit,
        getVolumeUnit,
        getLengthUnit,
        getContainerModes,
        getUnlocos,
        getChargeCode,
        getAllContainerModes,
        containerModeData,
        currencyData,
        shippingRatesData,
        serviceRatesData,
        containerTypeData,
        weightUnitsData,
        volumeUnitsData,
        lengthUnitsData,
        transportModesData,
        unlocodesData,
        chargeCodesData,
        allContainerModeData,
    }
}

export default useDropdown
