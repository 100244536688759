import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IContactNotify } from './form-booking.interface'
import { IFormBooking } from 'form-validation/form-booking.validation'
import moment from 'moment'

export const createBookingTemporary: IFormBooking = {
    bookingNo: 'B121254154',
    createdBy: 'Admin A',
    creationDate: moment().format('DD/MM/YYYY'),
    agent: '',
    notify: '',
    status: 'New',
    transportMode: '',
    etd: '',
    eta: '',
    originLocation: {
        origin: '',
        country: '',
        state: '',
    },
    destinationLocation: {
        destination: '',
        country: '',
        state: '',
    },
    consignorPickUpAddress: {
        consignor: '',
        address: '',
        address1: '',
    },
    consigneeDeliveryAddress: {
        consignee: '',
        address: '',
        address1: '',
    },
    inCludePickUp: false,
    withDelivery: false,
    contactAgent: {
        agent: '',
        agentEmail: '',
        agentPhoneNumber: '',
    },
    contactsNotify: [
        {
            notify: '',
            notifyEmail: '',
            notifyPhoneNumber: '',
        },
    ],
}

export const bookingDetails: IFormBooking = {
    bookingNo: 'B121254154',
    createdBy: 'Admin A',
    creationDate: '03/07/2024',
    agent: '',
    notify: '',
    status: 'New',
    transportMode: '',
    etd: '2024-07-01T17:00:00.000Z',
    eta: '2024-06-30T17:00:00.000Z',
    originLocation: {
        origin: 'IIDPS',
        country: 'INDONESIA',
        state: 'BALI',
    },
    destinationLocation: {
        destination: 'FRLEH',
        country: 'FRANCE',
        state: 'LE HARVE',
    },
    consignorPickUpAddress: {
        consignor: 'BATIK BALI STORE',
        address: 'BATIK BALI RENON',
        address1: 'Jalan Ahmad Dahlan No. 567, Denpasar, Bali',
    },
    consigneeDeliveryAddress: {
        address: 'SAN FRANSISCO 123',
        address1: '123 San Fransisco Street, 12345, Le Harv e',
        consignee: 'RISDIAN ISBINTARA',
    },
    inCludePickUp: false,
    withDelivery: false,
    contactAgent: {
        agent: 'VIONA',
        agentEmail: 'viona@gmail.com',
        agentPhoneNumber: '+628123324421',
    },
    contactsNotify: [
        {
            notify: 'JAMES',
            notifyEmail: 'james@gmail.com',
            notifyPhoneNumber: '123-456-7890',
        },
    ],
}

export const contactNotifyOptionsDummy: IDropdownItem[] = [
    {
        label: 'JAMES',
        value: 'JAMES',
    },
    {
        label: 'JHON',
        value: 'JHON',
    },
]

export const contactNotifyDummy: IContactNotify[] = [
    {
        notify: 'JAMES',
        notifyEmail: 'james@gmail.com',
        notifyPhoneNumber: '123-456-7890',
    },
    {
        notify: 'JHON',
        notifyEmail: 'jhon@gmail.com',
        notifyPhoneNumber: '098-765-4321',
    },
]
