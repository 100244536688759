import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { quoteSummaryPillHeaders } from './form.static'
import { useFormik } from 'formik'
import useQuoteValidation, {
    IFormQuote,
    IFormServiceRate,
} from 'form-validation/quote.validation'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import useOverlay from '@components/overlay/overlay.service'
import {
    getQuotationDetail,
    submitQuotation,
} from 'repository/quotes.repository'
import moment from 'moment'

const useNewQuote = (isDetailPage: boolean) => {
    const navigate = useNavigate()
    const serviceRatesOverlay = useOverlay()
    const params = useParams()

    // states
    const [selectedTransport, setSelectedTransport] =
        useState<IDropdownItem<ITransportMode> | null>(null)
    const [pillItems, setPillItems] = useState(quoteSummaryPillHeaders[0])
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const dropdownService = useDropdown()

    // formik
    const quoteValidtion = useQuoteValidation()
    const formik = useFormik<IFormQuote>({
        validationSchema: quoteValidtion.formQuoteSchema,
        initialValues: quoteValidtion.initialValue,
        onSubmit: (values) => {
            submitForm(values)
        },
    })
    const formikValues = formik.values
    const {
        transport_mode,
        container_mode,
        origin_location,
        destination_location,
    } = formikValues

    const submitForm = async (value: IFormQuote) => {
        setIsSubmitLoading(true)
        const renewValue: IFormQuote = {
            ...value,
            quote_date: moment(value.quote_date).format('YYYY-MM-DD HH:mm:ss'),
            etd: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
        }
        try {
            await submitQuotation(renewValue)
            setIsSubmitLoading(false)
            navigate('/quotes')
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const deleteServiceRateById = (index: number) => {
        const serviceRateData = formik.values.services
        if (serviceRateData.length === 1) {
            return
        }
        const updateData = serviceRateData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, services: updateData }))
    }

    const addNewRow = () => {
        const serviceRateData = formik.values.services
        const newServiceRate: IFormServiceRate = {
            charge_code: { code: '' },
            price: 0,
            unit: '',
            qty: 0,
            total: 0,
            code: '',
        }
        formik.setValues((state) => ({
            ...state,
            services: [...serviceRateData, newServiceRate],
        }))
    }

    const getDetailData = async () => {
        const code = params.id
        if (!code) {
            navigate('/quotes')
            return
        }
        const response = await getQuotationDetail(code)
        if (!response) {
            navigate('/quotes')
            return
        }

        const data = response.data
        dropdownService.getContainerModes(data.transport_mode)
        const newValue: IFormQuote = {
            container_mode: data.container_mode,
            currency: data.currency,
            customer_name: data.customer_name,
            name: data.name,
            quote_date: data.quote_date,
            etd: data.etd,
            doc_validity_period: data.doc_validity_period,
            transport_mode: data.transport_mode,
            origin_location: data.origin_location,
            destination_location: data.destination_location,
            weight: data.weight,
            weight_unit: data.weight_unit,
            volume: data.volume,
            volume_unit: data.volume_unit,
            shipping_rate: {
                code: data.shipping_rate.code,
                price: 0,
                qty: 0,
                total: 0,
                priceString: '',
                currencyShort: '',
                unit: '',
                totalString: '',
            },
            serviceTemplateName: 'Custom',
            serviceTemplateCode: '',
            services: data.services.map((d) => {
                const val: IFormServiceRate = {
                    code: d.code,
                    charge_code: { code: d.code },
                    qty: d.qty,
                    price: 0,
                    unit: '',
                    total: 0,
                }
                return val
            }),
            notes: data.notes,
        }
        formik.setValues(newValue)
    }

    useEffect(() => {
        if (isDetailPage) {
            getDetailData()
        }
    }, [])
    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getCurrency()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getServiceRates()
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    useEffect(() => {
        if (
            !transport_mode ||
            !container_mode ||
            !origin_location.code ||
            !destination_location.code
        ) {
            return
        }
        dropdownService.getShippingRates({
            transport: transport_mode,
            container: container_mode,
            origin: origin_location.code,
            destination: destination_location.code,
        })
    }, [transport_mode, container_mode, origin_location, destination_location])

    useEffect(() => {
        if (!selectedTransport) return
        dropdownService.getContainerModes(selectedTransport.value as string)
    }, [selectedTransport])

    return {
        navigate,
        setPillItems,
        deleteServiceRateById,
        addNewRow,
        setSelectedTransport,
        formik,
        pillItems,
        dropdownService,
        formikValues,
        serviceRatesOverlay,
        isSubmitLoading,
    }
}

export default useNewQuote
