import { IMeta } from '@components/pagination/pagination.interface'
import { IBookings } from './bookings.interface'

export const BookingDataDummy: IBookings[] = [
    {
        bookingNo: 'B446019574',
        transportMode: 'air',
        consignee: 'North American Imports LLC',
        shipper: 'Pacific Rim Exports',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        incPickup: false,
        incDelivery: true,
        etd: '01-DEC-24',
        createdBy: 'Staff A',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'new',
    },
    {
        bookingNo: 'B446019575',
        transportMode: 'sea',
        consignee: 'PEARL',
        shipper: 'Asia Exporters Ltd.',
        origin: 'JPTYO - Tokyo, 13, Japan',
        destination: 'USLAX - Los Angeles, CA, United States of America',
        incPickup: true,
        incDelivery: true,
        etd: '20-DEC-24',
        createdBy: 'Staff A',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'new',
    },
    {
        bookingNo: 'B446019576',
        transportMode: 'sea',
        consignee: 'PEARL',
        shipper: 'North American Imports LLC',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        incPickup: true,
        incDelivery: false,
        etd: '25-DEC-24',
        createdBy: 'Staff A',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'new',
    },
    {
        bookingNo: 'B446019577',
        transportMode: 'air',
        consignee: 'CARLA WEST',
        shipper: 'Asia Exporters Ltd.',
        origin: 'FRPAR - France, Paris',
        destination: 'DEBER - Germany, Berlin',
        incPickup: false,
        incDelivery: false,
        etd: '19-JAN-24',
        createdBy: 'Staff A',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'new',
    },
    {
        bookingNo: 'B446019578',
        transportMode: 'sea',
        consignee: 'PEARL',
        shipper: 'North American Imports LLC',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        incPickup: true,
        incDelivery: false,
        etd: '25-DEC-24',
        createdBy: 'Staff A',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'new',
    },
    {
        bookingNo: 'B446019579',
        transportMode: 'air',
        consignee: 'Elena Petrova',
        shipper: 'North American Imports LLC',
        origin: 'DEBER - Germany, Berlin',
        destination: 'FRPAR - France, Paris',
        incPickup: false,
        incDelivery: true,
        etd: '19-JAN-24',
        createdBy: 'Staff D',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'inProgress',
    },
    {
        bookingNo: 'B446019580',
        transportMode: 'sea',
        consignee: 'PEARL',
        shipper: 'Asia Exporters Ltd.',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'JPYOK - Yokohama, 14, Japan',
        incPickup: true,
        incDelivery: true,
        etd: '01-JAN-24',
        createdBy: 'Staff D',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'inProgress',
    },
    {
        bookingNo: 'B446019581',
        transportMode: 'air',
        consignee: 'ZENDYA GUN',
        shipper: 'Asia Exporters Ltd.',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'DEBER - Germany, Berlin',
        incPickup: true,
        incDelivery: true,
        etd: '25-JAN-24',
        createdBy: 'Staff D',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'inProgress',
    },
    {
        bookingNo: 'B446019582',
        transportMode: 'sea',
        consignee: 'JUE WAN',
        shipper: 'Pacific Rim Exports',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'DEBER - Germany, Berlin',
        incPickup: true,
        incDelivery: true,
        etd: '25-JAN-24',
        createdBy: 'Staff D',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'inProgress',
    },
    {
        bookingNo: 'B446019583',
        transportMode: 'air',
        consignee: 'JUE WAN',
        shipper: 'Pacific Rim Exports',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'DEBER - Germany, Berlin',
        incPickup: true,
        incDelivery: true,
        etd: '25-JAN-24',
        createdBy: 'Staff D',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'inProgress',
    },
    {
        bookingNo: 'B446019584',
        transportMode: 'air',
        consignee: 'James bond',
        shipper: 'Tionale Consignee Australia',
        origin: 'JPYOK - Yokohama, 14, Japan',
        destination: 'JPTYO - Tokyo, 13, Japan',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'closed',
    },
    {
        bookingNo: 'B446019585',
        transportMode: 'sea',
        consignee: 'Maria Hernandez',
        shipper: 'Tionale Consignee Australia',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'THBMT - Bangkok, Thailand',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'closed',
    },
    {
        bookingNo: 'B446019586',
        transportMode: 'sea',
        consignee: 'Mei Ling',
        shipper: 'Asia Exporters Ltd.',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'FRPAR - France, Paris',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'closed',
    },
    {
        bookingNo: 'B446019587',
        transportMode: 'air',
        consignee: 'ZENDYA GUN',
        shipper: 'North American Imports LLC',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'USLAX - Los Angeles, CA, United States of America',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'canceled',
    },
    {
        bookingNo: 'B446019588',
        transportMode: 'sea',
        consignee: 'Amit Patel',
        shipper: 'Pacific Rim Exports',
        origin: 'IDSBY - Surabaya, Indonesia',
        destination: 'FRPAR - France, Paris',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'canceled',
    },
    {
        bookingNo: 'B446019589',
        transportMode: 'air',
        consignee: 'PEARL',
        shipper: 'Pacific Rim Exports',
        origin: 'AUMEL - Melbourne, VIC, Australia',
        destination: 'JPTYO - Tokyo, 13, Japan',
        incPickup: true,
        incDelivery: true,
        etd: '09-DEC-24',
        createdBy: 'Staff E',
        creationDate: '31/12/24, 15:00',
        lastUpdated: '31/12/24, 15:00',
        status: 'canceled',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}
