import Keycloak from 'keycloak-js'
import KeycloakV22 from 'keycloak-js-v22'
import { IKeycloak } from 'repository/interface/keycloak.interface'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import {
    keycloakDataSelector,
    logoutAction,
    setLoginData,
} from 'pages/login/login.slice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { resetStore } from 'store'
import { useNavigate } from 'react-router-dom'

export const useKeycloakService = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const keycloakSelector = useSelector(keycloakDataSelector)

    const login = async (
        kcUrl: string = '',
        kcRealm: string = '',
        kcClient: string = '',
    ) => {
        if (kcUrl === '' || kcRealm === '' || kcClient === '') {
            console.error('Mandatory Config cannot be emmpty')
            console.error(kcUrl, kcRealm, kcClient)
            return
        }
        try {
            let kc = new Keycloak({
                url: kcUrl,
                realm: kcRealm,
                clientId: kcClient,
            })
            if (kcUrl.includes('keycloak-dev.logistical.one')) {
                // dev keycloak still use version 22
                kc = new KeycloakV22({
                    url: kcUrl + 'auth/',
                    realm: kcRealm,
                    clientId: kcClient,
                })
            }

            await kc.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
                pkceMethod: 'S256',
                redirectUri: window.location.origin + '/#/login?',
            })
            // ignore functions
            const {
                accountManagement,
                clearToken,
                createAccountUrl,
                createLoginUrl,
                createLogoutUrl,
                createRegisterUrl,
                hasRealmRole,
                hasResourceRole,
                init,
                isTokenExpired,
                loadUserInfo,
                loadUserProfile,
                login,
                logout,
                register,
                updateToken,
                ...kcExtra
            } = kc
            const { endpoints, ...kcValue } = kcExtra as IKeycloak

            const userSession: IUserAuth = {
                email: kcValue.idTokenParsed.email,
                fullName:
                    kcValue.idTokenParsed.given_name +
                    kcValue.idTokenParsed.family_name,
                username: kcValue.idTokenParsed.preferred_username,
                token: kcValue.token,
                exp: kcValue.tokenParsed.exp,
                keycloak: {
                    ...kcValue,
                    logoutUrl: kc.createLogoutUrl(),
                },
            }

            // dispatch
            dispatch(setLoginData(userSession))
            navigate('/dashboard?&state=' + kcValue?.tokenParsed.session_state)
        } catch (error) {
            console.error('Failed to initialize adapter:', error)
        }
    }

    const logout = async (
        kcUrl: string = '',
        kcRealm: string = '',
        kcClient: string = '',
    ) => {
        if (kcUrl === '' || kcRealm === '' || kcClient === '') {
            console.error('Mandatory Config cannot be emmpty')
            console.error(kcUrl, kcRealm, kcClient)
            return
        }

        let kc = new Keycloak({
            url: kcUrl,
            realm: kcRealm,
            clientId: kcClient,
        })
        if (kcUrl.includes('keycloak-dev.logistical.one')) {
            // dev keycloak still use version 22
            kc = new KeycloakV22({
                url: kcUrl + 'auth/',
                realm: kcRealm,
                clientId: kcClient,
            })
        }

        await kc.init({
            checkLoginIframe: true,
            pkceMethod: 'S256',
            redirectUri: window.location.origin + '/#/login',
            token: keycloakSelector?.token,
            idToken: keycloakSelector?.idToken,
            refreshToken: keycloakSelector?.refreshToken,
        })
        kc.logout()

        dispatch(logoutAction())
        resetStore()
    }

    return {
        login,
        logout,
    }
}
