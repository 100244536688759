import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResponseData } from 'common/common.interface'
import { IMeta } from '@components/pagination/pagination.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { IUser, IUserActivities } from 'repository/interface/user.interface'
import { RootState } from 'store'
import { IUserAccessFilter } from './user-access.interface'
import { tabItemsInitial } from './user-access.static'
import { formatDateTime } from '@services/common.service'

interface IUserAccessSlice {
    data: IUser[]
    meta: IMeta
    statusSelected: ITabItem
    filter: IUserAccessFilter
    tabItems: ITabItem[]
    activityLogs: IUserActivities[]
}

export const initialState: IUserAccessSlice = {
    data: [],
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    statusSelected: {
        value: 'Active',
        key: '',
        label: '',
        totalData: 0,
    },
    filter: { pageNumber: 1, pageSize: 50 },
    tabItems: tabItemsInitial,
    activityLogs: [],
}

const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {
        setUserData(state, action: PayloadAction<IUser[]>) {
            const user = action.payload
            const meta: IMeta = {
                current_page: 1,
                from: 1,
                to: user.length,
                index_start: 1,
                index_end: user.length,
                per_page: user.length,
                last_page: 1,
                total_page: 1,
                total_Items: user.length,
            }
            return {
                ...state,
                data: user,
                meta,
            }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const tabItems = action.payload
            return {
                ...state,
                tabItems,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload
            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<IUserAccessFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setFilterSearchUser(state, action: PayloadAction<string>) {
            const search = action.payload
            const filter = { ...state.filter, search }
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
        setActivityLogs(
            state,
            action: PayloadAction<IResponseData<IUserActivities[]>>,
        ) {
            const data = action.payload.data
            const activityLogs = data?.map((item) => ({
                ...item,
                time: item.time ? formatDateTime(item.time) : '',
            }))
            return {
                ...state,
                activityLogs,
            }
        },
    },
})

// these all the variables used for selector
export const tabStatusFilterSelector = (state: RootState) =>
    state.user.statusSelected || {}
export const filterSelector = (state: RootState) => state.user.filter || {}
export const userDataSelector = (state: RootState) => state.user.data || {}
export const userMeta = (state: RootState) => state.user.meta || {}
export const tabItemsSelector = (state: RootState) => state.user.tabItems || []
export const activityLogsSelector = (state: RootState) =>
    state.user.activityLogs || []

// all actions
export const {
    setUserData,
    setSelectedStatus,
    setFilter,
    setFilterSearchUser,
    setPageNumber,
    setTabItems,
    setActivityLogs,
} = userAccessSlice.actions

// Reducer
export default userAccessSlice.reducer
