import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportModeCardItem } from './form-booking.interface'

export const formBookingTransportMode: ITransportModeCardItem[] = [
    {
        icon: 'ri-plane-line',
        title: 'AIR FREIGHT',
        description:
            'Air cargo, is the transportation of goods by air using airplanes',
        value: 'air',
    },
    {
        icon: 'ri-ship-line',
        title: 'SEA FREIGHT',
        description:
            'Involves transporting goods by ships or vessels over oceans',
        value: 'air',
    },
]

export const bookingStatusOptions: IDropdownItem[] = [
    {
        label: 'New',
        value: 'New',
        backgroudColor: 'var(--loglines-extra-blue-v3)',
        color: 'var(--loglines-extra-blue-v4)',
    },
    {
        label: 'In Progress',
        value: 'In Progress',
        backgroudColor: 'var(--loglines-extra-red-v3)',
        color: 'var(--loglines-extra-red-v4)',
    },
    {
        label: 'Closed',
        value: 'Closed',
        backgroudColor: 'var(--loglines-gray-v1)',
        color: 'var(--loglines-gray-v5)',
    },
    {
        label: 'Canceled',
        value: 'Canceled',
        backgroudColor: 'var(--loglines-red-v1)',
        color: 'var(--loglines-red-v3)',
    },
]
