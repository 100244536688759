import { jsx as _jsx } from "react/jsx-runtime";
var PreviewDocument = function (_a) {
    var _b = _a.fileUrl, fileUrl = _b === void 0 ? 'https://drive.google.com/file/d/18Yzm-UN1SCqt1CdoHabSA_degQ2lVWwJ/preview' : _b;
    return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    _jsx("iframe", { src: fileUrl, style: {
            width: '100%',
            height: '100%',
            border: 'none',
        } }));
};
export default PreviewDocument;
