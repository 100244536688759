/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import Overlay from '@components/overlay/overlay.component'
import useOverlay from '@components/overlay/overlay.service'

const ActionSimpleTableOverlay = ({
    content,
    functions,
}: {
    content: string
    functions?: {
        func1?: () => void
        func2: (() => void) | undefined
    }
}) => {
    const actionOverlayService = useOverlay()

    let propertyButton1 = null
    let propertyButton2 = null

    switch (content) {
        case 'addressList':
            propertyButton1 = {
                label: 'Edit Address',
                icon: 'ri-edit-2-line',
            }
            propertyButton2 = {
                label: 'Remove Address',
                icon: 'ri-delete-bin-6-line',
            }
            break
        case 'contactList':
            propertyButton1 = {
                label: 'Edit Contact',
                icon: 'ri-edit-2-line',
            }
            propertyButton2 = {
                label: 'Remove Contact',
                icon: 'ri-delete-bin-6-line',
            }
            break
        case 'accounts':
            propertyButton1 = {
                label: 'Remove Account',
                icon: 'ri-delete-bin-6-line',
            }
            break
        case 'eDocumentations':
            propertyButton1 = {
                label: 'Download',
                icon: 'ri-download-2-line',
            }
            propertyButton2 = {
                label: 'Remove',
                icon: 'ri-delete-bin-6-line',
            }
            break
    }

    return (
        <div className="">
            <Button
                onClick={() => {
                    actionOverlayService?.openOverlayHandling()
                }}
                icon="ri-more-2-line"
                className="border-none w-[2rem]"
            />
            <Overlay
                overlayService={actionOverlayService}
                className="!w-[11.1rem] p-1 !absolute"
            >
                <div className="">
                    {propertyButton1?.label && (
                        <Button
                            onClick={() => {
                                actionOverlayService?.closeOverlayHandling()
                                functions?.func1 && functions?.func1()
                            }}
                            icon={propertyButton1.icon}
                            className="border-none w-full flex justify-start items-center"
                            label={propertyButton1.label}
                            variant={
                                propertyButton2?.label
                                    ? 'default'
                                    : 'red-inverse'
                            }
                        />
                    )}

                    {propertyButton2?.label && (
                        <Button
                            onClick={() => {
                                actionOverlayService?.closeOverlayHandling()
                                functions?.func2 && functions?.func2()
                            }}
                            icon={propertyButton2.icon}
                            className="border-none w-full flex justify-start items-center"
                            label={propertyButton2.label}
                            variant="red-inverse"
                        />
                    )}
                </div>
            </Overlay>
        </div>
    )
}

export default ActionSimpleTableOverlay
