/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import { IAddressList } from '../../organisation-details/organisation-details.interface'
import { addressListHeaders } from '../../organisation-details/organisation-details.static'
import { IUseModal } from '@components/modal/modal.service'
import RemoveModalAddress from '../../organisation-details/components/remove-address-modal.component'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'

const FormAddress = ({
    data,
    detailsData,
    setDetails,
    setIsCreateAddress,
    createOrEditModal,
    removeAddressModal,
    func1,
    removeAddress,
    formikValues,
}: {
    data: IAddressList[]
    detailsData?: IAddressList
    setDetails: (data: IAddressList) => void
    setIsCreateAddress: (data: boolean) => void
    createOrEditModal: IUseModal
    removeAddressModal: IUseModal
    func1: (id: number, mainAddress: boolean) => void
    removeAddress: () => void
    formikValues?: IAddressList[]
}) => {
    const address = formikValues?.find((addr) => addr.id === detailsData?.id)

    return (
        <div className="flex gap-3 h-full">
            <div className="w-3/4 flex flex-col">
                <div className="text-size-L">Address ({data.length})</div>
                <div className="text-size-XS">
                    Please add the address for this organization/client. You can
                    add multiple addresses for this organization/client.
                </div>
                <SimpleTable<IAddressList>
                    headers={addressListHeaders}
                    data={data}
                    isAddRow={true}
                    addRowLabel="Add New Address"
                    variant="gray"
                    onRowClick={(data) => setDetails(data)}
                    customBuildParam4={() => ({
                        // set details
                        func1: () =>
                            detailsData && func1(detailsData?.id, true),
                        func2: () => {
                            setIsCreateAddress(false)
                            createOrEditModal.openModalHandling()
                        },
                        func3: () => {
                            removeAddressModal.openModalHandling()
                        },
                    })}
                    addRowClick={() => {
                        setIsCreateAddress(true)
                        createOrEditModal.openModalHandling()
                    }}
                />
            </div>
            <div className="w-1/4 border rounded flex flex-col">
                <div className="p-3">Address Details</div>

                {/* border */}
                <div className="border-b"></div>
                {/* border */}

                {detailsData && address && (
                    <div className="p-3 overflow-auto flex flex-col gap-3">
                        <ToggleSwitch
                            initialOn={detailsData?.mainAddress}
                            label="Main Address"
                            onClick={() => {
                                func1(detailsData.id, true)
                            }}
                        />
                        <FormInput
                            label="Address Name"
                            name={`address[${formikValues?.indexOf(address)}].addressName`}
                            parentDivClassName=" !mb-0"
                            placeholder="Enter Address Name"
                        />
                        <FormTextArea
                            label="Address 1"
                            name={`address[${formikValues?.indexOf(address)}].address1`}
                            placeholder="Enter Address"
                        />
                        <FormTextArea
                            label="Address 2"
                            name={`address[${formikValues?.indexOf(address)}].address2`}
                            placeholder="Enter Address"
                        />
                        <div className="flex gap-3">
                            <FormDropdown
                                name={`address[${formikValues?.indexOf(address)}].countryOrRegion`}
                                label="Country/Region"
                                placeholder="Select Country"
                                options={[
                                    {
                                        label: 'INDONESIA',
                                        value: 'INDONESIA',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                            <FormDropdown
                                name={`address[${formikValues?.indexOf(address)}].state`}
                                label="State"
                                placeholder="Select State"
                                options={[
                                    {
                                        label: 'BALI',
                                        value: 'BALI',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                        </div>
                        <div className="flex gap-3">
                            <FormDropdown
                                name={`address[${formikValues?.indexOf(address)}].city`}
                                label="City"
                                placeholder="Select City"
                                options={[
                                    {
                                        label: 'DENPASAR',
                                        value: 'DENPASAR',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                            <FormDropdown
                                name={`address[${formikValues?.indexOf(address)}].postCode`}
                                label="Postcode"
                                placeholder="Select Postcode"
                                options={[
                                    {
                                        label: '309218',
                                        value: '309218',
                                    },
                                ]}
                                parentDivClassName="w-1/2 !mb-0"
                            />
                        </div>
                        <FormInput
                            label="Unloco"
                            name={`address[${formikValues?.indexOf(address)}].unloco`}
                            parentDivClassName="!mb-0"
                            placeholder="Enter Unloco"
                        />
                        <FormInput
                            label="Related City/Port (Optional)"
                            name={`address[${formikValues?.indexOf(address)}].relatedCityOrPort`}
                            parentDivClassName="!mb-0"
                            placeholder="Enter Enter City/Port"
                        />
                        <ToggleSwitch
                            label={'Active Address'}
                            initialOn={true}
                        />
                    </div>
                )}
            </div>
            {detailsData?.addressName && (
                <RemoveModalAddress
                    modalService={removeAddressModal}
                    type="removeAddress"
                    onClick={() => {
                        removeAddress()
                    }}
                    data={detailsData.addressName}
                />
            )}
        </div>
    )
}

export default FormAddress
