import * as YUP from 'yup'
import { requiredMessage } from './validation-message.static'

export interface IFUser {
    // User Profile
    fullName: string
    jobTitle: string
    jobCategory: string
    // User Account
    email: string
    username: string
    // User Role
    role: string
    site: string[]
    // User Address
    country?: string
    province?: string
    city?: string
    addressDetail?: string
    // User Contact
    mobilePhoneNumber?: string
    homePhoneNumber?: string
    emergencyContact?: string
    phoneNumber?: string
}

export interface IFUserPayload extends IFUser {
    role: any
}

export const userFormValidation: YUP.Schema<IFUser> = YUP.object().shape({
    // User Profile
    fullName: YUP.string().required(requiredMessage('Full Name')),
    jobTitle: YUP.string().required(requiredMessage('Job Title')),
    jobCategory: YUP.string().required(requiredMessage('Job Category')),
    // User Role
    role: YUP.string().required(requiredMessage('Role')),
    site: YUP.array()
        .of(YUP.string().required())
        .required(requiredMessage('Site'))
        .default([]),

    // User Address
    country: YUP.string(),
    province: YUP.string(),
    city: YUP.string(),
    addressDetail: YUP.string(),

    // User Contact
    mobilePhoneNumber: YUP.string(),
    homePhoneNumber: YUP.string(),
    emergencyContact: YUP.string(),
    phoneNumber: YUP.string(),

    // User Account
    username: YUP.string().required(requiredMessage('User ID')).default(''),
    email: YUP.string()
        .email('Invalid format (eg. loglines@gmail.com)')
        .max(50, '')
        .required(requiredMessage('Email'))
        .default(''),
})

// form user initial data
export const userFormInitial: IFUser = {
    fullName: '',
    jobTitle: '',
    jobCategory: '',
    email: '',
    username: '',
    site: [],
    role: '',
}

// Dummy Edit User
export const userFormUpdate: IFUser = {
    fullName: 'Alice Johnson',
    jobTitle: 'Logistics Coordinator',
    jobCategory: 'Logistics',
    email: 'alice.johnson@example.com',
    username: 'aliceJ',
    site: ['Kuta Site, Bali', 'Sanur Site, Bali'],
    role: 'admin',
}
