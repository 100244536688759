/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { ITransportModeCardItem } from '../form-booking.interface'

const TransportModeCard = ({
    items,
    setTransportModeItem,
}: {
    items: ITransportModeCardItem[]
    setTransportModeItem: (data: ITransportModeCardItem) => void
}) => {
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div className="flex gap-3 ">
            {items.map((item, idx) => {
                const activeClass =
                    idx === activeIndex
                        ? 'border-2 themes-border-brand-v5 themes-text-brand-v5 themes-bg-brand-v1'
                        : 'border'
                const iconActiveClass =
                    idx === activeIndex
                        ? 'themes-bg-brand-v2'
                        : 'themes-bg-gray-v1'

                return (
                    <div
                        className={`p-3 w-full flex flex-col items-center cursor-pointer rounded gap-3 ${activeClass}`}
                        onClick={() => {
                            setTransportModeItem(item)
                            setActiveIndex(idx)
                        }}
                    >
                        <i
                            className={`rounded-full w-[60px] h-[60px] text-[32px] flex items-center justify-center ${item.icon} ${iconActiveClass}`}
                        ></i>
                        <div className="flex flex-col items-center">
                            <div>{item.title}</div>
                            <div className="text-size-XS">
                                {item.description}
                            </div>
                        </div>
                    </div>
                )
            })}

            {/* <div
                className={`p-3 flex flex-col items-center  rounded gap-3 ${activeClass}`}
            >
                <i className="ri-plane-line themes-bg-brand-v2 rounded-full w-[60px] h-[60px] text-[32px] flex items-center justify-center"></i>
                <div className="flex flex-col items-center">
                    <div>AIR FREIGHT</div>
                    <div className="text-size-XS">
                        Air cargo, is the transportation of goods by air using
                        airplanes
                    </div>
                </div>
            </div>
            <div className="p-3 flex flex-col items-center border rounded gap-3">
                <i className="ri-ship-line themes-bg-gray-v1 rounded-full w-[60px] h-[60px] text-[32px] flex items-center justify-center"></i>
                <div className="flex flex-col items-center">
                    <div>SEA FREIGHT</div>
                    <div className="text-size-XS">
                        Involves transporting goods by ships or vessels over
                        oceans
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default TransportModeCard
