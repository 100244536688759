import {
    IAddressList,
    IContactList,
} from 'pages/organisation/organisation-details/organisation-details.interface'
import * as Yup from 'yup'

export interface ICreateOrgValidation {
    org: {
        organisationCode: string
        fullName: string
        category: string
        email?: string
        emailAccounting?: string
        phoneNumber?: string
        mobileNumber?: string
        fax?: string
        websiteUrl?: string
        language: string
        remark?: string
        govermentTaxType: string
        govermentTaxNumber: string
        isActive: boolean
        isreceivables: boolean
    }
    address: IAddressList[]
    contact: IContactList[]
}

export const createOrgValidation = Yup.object({
    org: Yup.object({
        organisationCode: Yup.string().required('Organisation Code required'),
        fullName: Yup.string().required('Fuil Name required'),
        // category: Yup.string().required('Category required'),
        // govermentTaxType: Yup.string().required('Goverment Tax Type required'),
        // govermentTaxNumber: Yup.string().required(
        //     'Goverment Tax Number required',
        // ),
    }),
    address: Yup.array().of(
        Yup.object({
            addressName: Yup.string().required('Address Name is required'),
            // unloco: Yup.string().required('UN/LOCODE is required'),
            // countryOrRegion: Yup.string().required(
            //     'Country or Region is required',
            // ),
            // state: Yup.string().required('State is required'),
            // city: Yup.string().required('City is required'),
            // postcode: Yup.string().required('Postcode is required'),
        }),
    ),
    contact: Yup.array().of(
        Yup.object({
            contactName: Yup.string().required('Contact Name is required'),
        }),
    ),
})

export const createOrgInitialValue: ICreateOrgValidation = {
    org: {
        organisationCode: '',
        fullName: '',
        category: '',
        language: '',
        govermentTaxType: '',
        govermentTaxNumber: '',
        isActive: true,
        isreceivables: true,
    },
    address: [],
    contact: [],
}
