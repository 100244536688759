var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.style.css';
var CustomToast = function (_a) {
    var header = _a.header, message = _a.message;
    return (_jsx("div", __assign({ className: "flex ml-3 pl-3 py-3 themes-bg-white text-gray-700 " }, { children: _jsxs("div", { children: [_jsx("div", __assign({ className: " font-bold text-size-M" }, { children: _jsx("span", { children: header }) })), _jsx("div", __assign({ className: "!text-size-xs" }, { children: _jsx("span", { children: message.split('\n').map(function (str) { return (_jsx("p", { children: str }, str)); }) }) }))] }) })));
};
export var Toast = function (_a) {
    var header = _a.header, message = _a.message, type = _a.type;
    var getBackgroundColor = function () {
        switch (type) {
            case 'success':
                return '#07bc0c'; // Green for success
            case 'error':
                return '#e74c3c'; // Red for error
            case 'info':
                return '#3498db'; // Blue for info
            case 'warning':
                return '#f1c40f'; // Yellow for warning
            default:
                return '#343a40'; // Default background color
        }
    };
    var toastOptions = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        bodyStyle: {
            backgroundColor: getBackgroundColor(),
        },
        closeButton: false,
    };
    switch (type) {
        case 'success':
            toast.success(_jsx(CustomToast, { header: header, message: message, type: type }), toastOptions);
            break;
        case 'error':
            toast.error(_jsx(CustomToast, { header: header, message: message, type: type }), toastOptions);
            break;
        case 'info':
            toast.info(_jsx(CustomToast, { header: header, message: message, type: type }), toastOptions);
            break;
        case 'warning':
            toast.warning(_jsx(CustomToast, { header: header, message: message, type: type }), toastOptions);
            break;
        case 'default':
            toast(_jsx(CustomToast, { header: header, message: message, type: type }), toastOptions);
            break;
        default:
            break;
    }
};
