/* eslint-disable no-unused-vars */
import {
    IFormBooking,
    formBookingInitialValue,
    formBookingValidation,
} from 'form-validation/form-booking.validation'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import {
    bookingStatusOptions,
    formBookingTransportMode,
} from './form-booking.static'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useNavigate } from 'react-router-dom'
import {
    bookingDetails,
    contactNotifyDummy,
    createBookingTemporary,
} from './form-booking.dummy'
import { Toast } from '@components/toast/toast.component'
import ConfirmationFormModal from './components/confirmation-form-modal.component'
import { useModal } from '@components/modal/modal.service'
import Button from '@components/button/button.component'
import { IButton } from '@components/button/button.interface'

const useFormBooking = ({ isPageDetails }: { isPageDetails: boolean }) => {
    const navigate = useNavigate()

    // states
    const [transportModeItem, setTransportModeItem] = useState(
        formBookingTransportMode[0],
    )
    const [bookingStatusOption, setBookingStatusOption] = useState<
        IDropdownItem[]
    >([])
    const [bookingTemproraryData, setBookingTemproraryData] =
        useState<IFormBooking>()
    const [initialValue, setInitialValue] = useState<IFormBooking>(
        isPageDetails ? bookingDetails : formBookingInitialValue,
    )
    const [typeModalConfirmation, setTypeModalConfirmation] = useState<
        | 'cancelBooking'
        | 'reOpenBooking'
        | 'cancelCreateBooking'
        | 'exitEditMode'
    >('cancelBooking')

    // modal
    const confimationFormModal = useModal()

    // formik
    const formik = useFormik<IFormBooking>({
        validationSchema: formBookingValidation,
        initialValues: initialValue,
        onSubmit: (values, { resetForm }) => {
            if (isPageDetails) {
                Toast({
                    header: 'Booking Details Updated',
                    message: `Booking:${values.bookingNo} successfully updated.`,
                    type: 'success',
                })

                setInitialValue(values)
                resetForm({ values: values })
                setBookingTemproraryData(values)
            } else {
                Toast({
                    header: 'Booking Created',
                    message: `New Booking: ${values.bookingNo} is successfully created.`,
                    type: 'success',
                })
                navigate('/bookings')
            }
        },
    })

    useEffect(() => {
        if (isPageDetails) {
            setBookingTemproraryData(bookingDetails)
        } else {
            setBookingTemproraryData(createBookingTemporary)
        }
    }, [])

    useEffect(() => {
        let data: IDropdownItem[] = []
        if (bookingTemproraryData?.status === 'New') {
            data = bookingStatusOptions.filter(
                (item) =>
                    item.value !== 'In Progress' && item.value !== 'Closed',
            )
        } else {
            data = bookingStatusOptions.filter((item) => item.value !== 'New')
        }
        setBookingStatusOption(data)
    }, [bookingTemproraryData?.status])

    const addMoreNotify = () => {
        const newContact = {
            notify: '',
            notifyEmail: '',
            notifyPhoneNumber: '',
        }
        formik.setFieldValue('contactsNotify', [
            ...formik.values.contactsNotify,
            newContact,
        ])
    }

    const removeRowNotify = (idx: number) => {
        const newContactsNotify = [...formik.values.contactsNotify]
        newContactsNotify.splice(idx, 1)
        formik.setFieldValue('contactsNotify', newContactsNotify)
    }

    const formNotifyadditionalClick = (idx: number, value: string) => {
        const data = contactNotifyDummy.find((item) => item.notify === value)
        formik.setFieldValue(
            `contactsNotify[${idx}].notifyEmail`,
            data?.notifyEmail,
        )
        formik.setFieldValue(
            `contactsNotify[${idx}].notifyPhoneNumber`,
            data?.notifyPhoneNumber,
        )
    }

    const headerButton = () => {
        let buttonProperty1: IButton | undefined
        let buttonProperty2: IButton | undefined
        if (isPageDetails) {
            buttonProperty1 = {
                label: 'DISCARD CHANGES',
                onClick: () => {
                    formik.resetForm()
                },
                variant: 'red-inverse',
                isDisabled: !formik.dirty,
            }
            buttonProperty2 = {
                label: 'SAVE CHANGES',
                onClick: async () => {
                    await formik.submitForm()
                    formik.resetForm()
                },
                variant: 'brand',
                isDisabled: !formik.dirty,
            }
        } else {
            buttonProperty1 = {
                label: 'CREATE BOOKING',
                onClick: () => {
                    formik.submitForm()
                },
                variant: 'brand',
            }
        }

        return (
            <>
                <Button
                    onClick={() => buttonProperty1?.onClick()}
                    label={buttonProperty1?.label}
                    variant={buttonProperty1?.variant}
                    isDisabled={buttonProperty1?.isDisabled}
                />
                {buttonProperty2 && (
                    <Button
                        onClick={() => buttonProperty2?.onClick()}
                        label={buttonProperty2?.label}
                        variant={buttonProperty2?.variant}
                        isDisabled={buttonProperty2?.isDisabled}
                    />
                )}
            </>
        )
    }
    const footerButton = () => {
        let buttonProperty: IButton
        if (isPageDetails) {
            buttonProperty = {
                label: 'BACK',
                onClick: () => {
                    if (!formik.dirty) {
                        navigate(-1)
                    } else {
                        setTypeModalConfirmation('exitEditMode')
                        confimationFormModal.openModalHandling()
                    }
                },
            }
        } else {
            buttonProperty = {
                label: 'CANCEL CREATE',
                onClick: () => {
                    setTypeModalConfirmation('cancelCreateBooking')
                    confimationFormModal.openModalHandling()
                },
            }
        }

        return (
            <>
                <Button
                    onClick={() => buttonProperty.onClick()}
                    label={buttonProperty.label}
                />
            </>
        )
    }

    const contentConfimationModal = () => {
        let header: JSX.Element = <></>
        let message: JSX.Element = <></>
        let buttonSubmitProperty: IButton = {
            onClick: () => {},
        }

        switch (typeModalConfirmation) {
            case 'cancelCreateBooking':
                header = <>Cancel Create</>
                message = (
                    <>Are you sure you want to exit without saving changes?</>
                )
                buttonSubmitProperty = {
                    onClick: () => navigate(-1),
                    label: 'CANCEL CREATE',
                    variant: 'brand',
                }
                break
            case 'exitEditMode':
                header = <>Exit Edit Mode</>
                message = (
                    <>Are you sure you want to exit without saving changes?</>
                )
                buttonSubmitProperty = {
                    onClick: () => navigate(-1),
                    label: 'EXIT EDIT MODE ',
                    variant: 'brand',
                }
                break
        }

        const buttonSubmit = (
            <Button
                onClick={() => {
                    buttonSubmitProperty?.onClick()
                }}
                label={buttonSubmitProperty?.label}
                variant={buttonSubmitProperty?.variant}
                className="w-1/2"
            />
        )

        return {
            header,
            message,
            buttonSubmit,
        }
    }

    // components
    const allModal = (
        <>
            <ConfirmationFormModal
                modalService={confimationFormModal}
                content={contentConfimationModal}
            />
        </>
    )

    return {
        formik,
        transportModeItem,
        bookingStatusOption,
        allModal,
        confimationFormModal,
        headerButton,
        footerButton,
        formNotifyadditionalClick,
        navigate,
        setTransportModeItem,
        addMoreNotify,
        removeRowNotify,
    }
}

export default useFormBooking
