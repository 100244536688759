import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrganisationDataDummy } from './organisation.dummy'
import { IOrganisation } from './organisation.interface'
import Button from '@components/button/button.component'

const useOrganisation = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // state
    const [data, setData] = useState<IOrganisation[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Active',
        totalData: OrganisationDataDummy.filter(
            (x: any) => x.status === 'active',
        ).length,
        key: 'status',
        value: 'active',
        className: 'brand',
    })

    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabFilter])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            const filterData = OrganisationDataDummy.filter(
                (x) => x.status === tabFilter.value,
            )
            setData(filterData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const importDataButton = <Button label="Import Data" onClick={() => {}} />

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        importDataButton,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
    }
}

export default useOrganisation
