export const endpoints = {
    login: 'Authentication',
    quotations: 'quotations',
    containerTypes: 'container_types',
    currencies: 'currencies',
    transportMode: 'transport_modes',
    shippingRates: 'shipping_rates',
    serviceRates: 'service_rates',
    units: 'units',
    unlocodes: 'unlocodes',
    chargeCodes: 'charge_codes',
}
