import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChargeCode } from 'repository/interface/charge-code.interface'
import { ICurrency } from 'repository/interface/currency.interface'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnit } from 'repository/interface/unit.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { RootState } from 'store'

interface IDropdownInitial {
    currency: IDropdownItem<ICurrency>[]
    containerType: IDropdownItem[]
    transportMode: IDropdownItem<ITransportMode>[]
    shippingRates: IDropdownItem<IShippingRate>[]
    serviceRates: IDropdownItem<IServiceRate>[]
    weightUnits: IDropdownItem<IUnit>[]
    lengthUnits: IDropdownItem<IUnit>[]
    volumeUnits: IDropdownItem<IUnit>[]
    containerModes: IDropdownItem<ITransportMode>[]
    allContainerModes: IDropdownItem<ITransportMode>[]
    unlocodes: IDropdownItem<IUnlocodes>[]
    chargeCodes: IDropdownItem<IChargeCode>[]
}

const initialState: IDropdownInitial = {
    currency: [],
    containerType: [],
    transportMode: [],
    shippingRates: [],
    serviceRates: [],
    weightUnits: [],
    lengthUnits: [],
    volumeUnits: [],
    containerModes: [],
    unlocodes: [],
    chargeCodes: [],
    allContainerModes: [],
}

const dropdownSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setCurrency: (
            state,
            action: PayloadAction<IDropdownItem<ICurrency>[]>,
        ) => {
            state.currency = action.payload
        },
        setContainerType: (state, action: PayloadAction<IDropdownItem[]>) => {
            state.containerType = action.payload
        },
        setTransportMode: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.transportMode = action.payload
        },
        setShippingRates: (
            state,
            action: PayloadAction<IDropdownItem<IShippingRate>[]>,
        ) => {
            state.shippingRates = action.payload
        },
        setServiceRates: (
            state,
            action: PayloadAction<IDropdownItem<IServiceRate>[]>,
        ) => {
            state.serviceRates = action.payload
        },
        setWeightUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.weightUnits = action.payload
        },
        setVolumeUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.volumeUnits = action.payload
        },
        setLengthUnits: (
            state,
            action: PayloadAction<IDropdownItem<IUnit>[]>,
        ) => {
            state.lengthUnits = action.payload
        },
        setContainerModes: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.containerModes = action.payload
        },
        setAllContainerModes: (
            state,
            action: PayloadAction<IDropdownItem<ITransportMode>[]>,
        ) => {
            state.allContainerModes = action.payload
        },
        setUnlocodes: (
            state,
            action: PayloadAction<IDropdownItem<IUnlocodes>[]>,
        ) => {
            state.unlocodes = action.payload
        },
        setChargeCodes: (
            state,
            action: PayloadAction<IDropdownItem<IChargeCode>[]>,
        ) => {
            state.chargeCodes = action.payload
        },
    },
})

export const {
    setContainerType,
    setCurrency,
    setTransportMode,
    setShippingRates,
    setServiceRates,
    setWeightUnits,
    setVolumeUnits,
    setLengthUnits,
    setContainerModes,
    setAllContainerModes,
    setUnlocodes,
    setChargeCodes,
} = dropdownSlice.actions

export const dropdownCurrencySelector = (state: RootState) =>
    state.dropdown.currency
export const dropdownContainerTypeSelector = (state: RootState) =>
    state.dropdown.containerType
export const dropdownTransportModeSelector = (state: RootState) =>
    state.dropdown.transportMode
export const dropdownShippingRatesSelector = (state: RootState) =>
    state.dropdown.shippingRates
export const dropdownServiceRatesSelector = (state: RootState) =>
    state.dropdown.serviceRates
export const dropdownServiceLengthSelector = (state: RootState) =>
    state.dropdown.lengthUnits
export const dropdownServiceVolumeSelector = (state: RootState) =>
    state.dropdown.volumeUnits
export const dropdownServiceWeightSelector = (state: RootState) =>
    state.dropdown.weightUnits
export const dropdownContainerModeSelector = (state: RootState) =>
    state.dropdown.containerModes
export const dropdownAllContainerModeSelector = (state: RootState) =>
    state.dropdown.allContainerModes
export const dropdownUnlocodesSelector = (state: RootState) =>
    state.dropdown.unlocodes
export const dropdownChargeCodesSelector = (state: RootState) =>
    state.dropdown.chargeCodes

export default dropdownSlice.reducer
