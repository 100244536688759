import Button from '@components/button/button.component'
import { periodOptions, quoteSummaryPillHeaders } from './form.static'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import useNewQuote from './form.service'
import { FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'
import Pills from '@components/pills/pills.component'
import { IQuoteSummaryPills } from './form.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { ICurrency } from 'repository/interface/currency.interface'
import './form.style.css'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import OptionShippingRates from './components/option-shipping-rates'
import { IFormQuote, IFormServiceRate } from 'form-validation/quote.validation'
import { priceFormat } from 'common/common.service'
import ServiceRatesOverlay from './components/service-rates-overlay.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { IChargeCode } from 'repository/interface/charge-code.interface'

const FormQuote = ({ isDetailPage }: { isDetailPage: boolean }) => {
    const {
        formik,
        serviceRatesOverlay,
        dropdownService,
        pillItems,
        isSubmitLoading,
        navigate,
        setPillItems,
        setSelectedTransport,
        addNewRow,
        deleteServiceRateById,
    } = useNewQuote(isDetailPage)
    const isChargeList = pillItems.value === 'chargeList'

    // Formik Values
    const {
        currency,
        shipping_rate,
        services,
        serviceTemplateName,
        weight_unit,
    } = formik.values
    const currencyCode = currency.short_code
    const shippingRatesCurrency = shipping_rate.currencyShort
    const shippingRatesTotalString = shipping_rate.totalString
    const shippingRatesQty = shipping_rate.qty
    const shippingRatesUnit = shipping_rate.unit

    // Counting
    const sumServiceRateQty = (allServices: IFormServiceRate[]) => {
        let total = 0
        allServices.forEach((d) => {
            total += d.qty
        })
        return total
    }

    const sumServiceRatePrice = (allServices: IFormServiceRate[]) => {
        let total = 0
        allServices.forEach((d) => {
            total += d.total
        })
        return total
    }
    const totalServiceRatesQty = sumServiceRateQty(services)
    const totalServiceRatesPrice = sumServiceRatePrice(services)

    // Additional Handling
    const onShippingRateChange = (data: IDropdownItem<IShippingRate>) => {
        const dt = (data as IDropdownItem<IShippingRate>).additionalData
        const value = formik.values

        const newValue = {
            ...value,
            shipping_rate: {
                code: dt?.code ?? '',
                price: dt?.price,
                priceString: priceFormat(dt?.price ?? 0) + '/' + dt?.unit,
                currencyShort: dt?.currency.short_code,
                qty: 0,
                total: 0,
                totalString: priceFormat(0),
                unit: dt?.unit ?? '',
            },
        } as IFormQuote
        formik.setValues(newValue)
    }

    // Shipping Component
    const shippingForm = services.map((data, index) => {
        const nameString = `services[${index}]`
        return (
            <tr>
                <td className="p-2 pl-4  ">{index + 1}</td>
                <td className="p-2">
                    <FormDropdown
                        name={`${nameString}.charge_code.code`}
                        options={dropdownService.chargeCodesData}
                        placeholder="Select Services"
                        parentDivClassName="!mb-0"
                        isSearchable={true}
                        additionalOnClick={(data) => {
                            //set value
                            if (!data) return
                            const d = (data as IDropdownItem<IChargeCode>)
                                .additionalData
                            const dt: IFormServiceRate = {
                                charge_code: {
                                    code: d?.code ?? '',
                                },
                                price: d?.price ?? 0,
                                unit: d?.unit ?? '',
                                qty: 0,
                                total: 0,
                                code: d?.code ?? '',
                            }

                            formik.setFieldValue(nameString, dt)

                            // reset template
                            formik.setFieldValue(
                                'serviceTemplateName',
                                'Custom',
                            )
                            formik.setFieldValue('serviceTemplateCode', '')
                        }}
                    />
                </td>
                <td className="p-2">
                    <div className="flex items-start justify-between custom-input themes-bg-gray-v1">
                        <div className="w-[60px] h-[40px] px-2 flex justify-center items-center border-r">
                            {currencyCode}
                        </div>
                        <FormInput
                            placeholder="-"
                            name={`${nameString}.price`}
                            disabled
                            parentDivClassName="border-none !mb-0 w-full"
                        />
                    </div>
                </td>
                <td className="p-2">
                    <FormInput
                        name={`${nameString}.unit`}
                        placeholder="Unit"
                        parentDivClassName="!mb-0"
                        disabled
                    />
                </td>
                <td className="p-2">
                    <FormInput
                        type="number"
                        placeholder="Input Qty"
                        name={`${nameString}.qty`}
                        parentDivClassName="border-none !mb-0 w-full"
                        onChange={(e) => {
                            const value = e.target.value
                            const qty = value as unknown as number
                            const price = formik.values.services[index].price
                            const total = qty * price
                            formik.setFieldValue(`${nameString}.total`, total)
                            formik.setFieldValue(
                                `${nameString}.totalString'`,
                                priceFormat(total),
                            )
                        }}
                    />
                </td>
                <td className="p-2">
                    <div className="flex items-start justify-between custom-input themes-bg-gray-v1">
                        <div className="w-[60px] h-[40px] px-2 flex justify-center items-center border-r">
                            IDR
                        </div>
                        <FormInput
                            placeholder="-"
                            name={`${nameString}.total`}
                            disabled
                            parentDivClassName="border-none !mb-0 w-full"
                        />
                    </div>
                </td>
                <td className="p-2">
                    <Button
                        className="w-full"
                        icon="ri-delete-bin-line"
                        variant="red-inverse"
                        onClick={() => {
                            deleteServiceRateById(index)
                            formik.setFieldValue(
                                'serviceTemplateName',
                                'Custom',
                            )
                            formik.setFieldValue('serviceTemplateCode', '')
                        }}
                        isDisabled={services.length < 2}
                    />
                </td>
            </tr>
        )
    })
    const chargeListComponent = (
        <>
            <div className="flex gap-4 w-full mb-4 mobile:flex-wrap">
                <div className="w-full themes-bg-white rounded-b-md">
                    <div className="flex justify-center items-center p-2 themes-bg-gray-v5 themes-text-white rounded-t-md">
                        Currency
                    </div>
                    <div className="p-2">
                        <FormDropdown
                            parentDivClassName="!mb-0"
                            name="currency.code"
                            placeholder="Select Currency"
                            options={dropdownService.currencyData}
                            additionalOnClick={(d) => {
                                const value = (d as IDropdownItem<ICurrency>)
                                    .additionalData?.short_code
                                formik.setFieldValue(
                                    'currency.short_code',
                                    value,
                                )
                            }}
                            isSearchable={true}
                        />
                    </div>
                </div>
                <div className="w-full themes-bg-white ">
                    <div className="flex justify-center items-center p-2 themes-bg-gray-v5 themes-text-white rounded-t-md">
                        Weight
                    </div>
                    <div className="p-2">
                        <div className="flex custom-input">
                            <FormInput
                                name="weight"
                                placeholder=""
                                parentDivClassName="w-[60%] border-none !mb-0"
                            />
                            <FormDropdown
                                className="border-none"
                                parentDivClassName="w-[40%] border-l themes-border-gray-v3 !mb-0"
                                name="weight_unit"
                                placeholder="Select"
                                options={dropdownService.weightUnitsData}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full themes-bg-white">
                    <div className="flex justify-center items-center p-2 themes-bg-gray-v5 themes-text-white rounded-t-md">
                        Volume Weight
                    </div>
                    <div className="p-2">
                        <div className="flex custom-input">
                            <FormInput
                                name="volume"
                                placeholder=""
                                parentDivClassName="w-[60%] border-none !mb-0"
                            />
                            <FormDropdown
                                className="border-none"
                                parentDivClassName="w-[40%] border-l themes-border-gray-v3 !mb-0"
                                name="volume_unit"
                                placeholder="Select"
                                options={dropdownService.volumeUnitsData}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full themes-bg-white rounded-md px-4">
                <div className="text-size-M font-bold p-4 pl-0">
                    Shipping Rates
                </div>

                <div className="-mx-4">
                    <table className="table-fixed w-full text-size-S themes-text-gray-v5">
                        <thead>
                            <tr className="border-t text-left border-b themes-border-gray-v3">
                                <th className="p-2 pl-4  w-[30%]">
                                    Shipping Mode
                                </th>
                                <th className="p-2 !w-[15%]">Price/Unit</th>
                                <th className="p-2 !w-[20%]">Unit</th>
                                <th className="p-2  !w-[10%]">Qty</th>
                                <th className="p-2 w-auto">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2 pl-4 !w-[25%]">
                                    <FormDropdown<IShippingRate>
                                        name="shipping_rate.code"
                                        options={
                                            dropdownService.shippingRatesData
                                        }
                                        placeholder="No Shipping Rates Selected"
                                        parentDivClassName="!mb-0"
                                        isSearchable={true}
                                        customOptionComponent={
                                            OptionShippingRates
                                        }
                                        additionalOnClick={(data) => {
                                            const dt =
                                                data as IDropdownItem<IShippingRate>
                                            onShippingRateChange(dt)
                                        }}
                                    />
                                </td>
                                <td className="p-2">
                                    <div className="flex items-start justify-between custom-input themes-bg-gray-v1">
                                        <div className="w-[60px] h-[40px] px-2 flex justify-center items-center border-r">
                                            {shippingRatesCurrency === ''
                                                ? '-'
                                                : shippingRatesCurrency}
                                        </div>
                                        <FormInput
                                            placeholder="-"
                                            name="shipping_rate.priceString"
                                            disabled
                                            parentDivClassName="border-none !mb-0 w-full"
                                        />
                                    </div>
                                </td>
                                <td className="p-2">
                                    <FormInput
                                        name="shipping_rate.unit"
                                        placeholder="Unit"
                                        parentDivClassName="!mb-0"
                                        disabled
                                    />
                                </td>
                                <td className="p-2">
                                    <FormInput
                                        type="number"
                                        placeholder="Input Qty"
                                        name="shipping_rate.qty"
                                        parentDivClassName="border-none !mb-0 w-full"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            const qty =
                                                value as unknown as number
                                            const price = shipping_rate.price
                                            const total = qty * price
                                            formik.setFieldValue(
                                                'shipping_rate.total',
                                                total,
                                            )
                                            formik.setFieldValue(
                                                'shipping_rate.totalString',
                                                priceFormat(total),
                                            )
                                        }}
                                    />
                                </td>
                                <td className="p-2">
                                    <div className="flex items-start justify-between custom-input themes-bg-gray-v1">
                                        <div className="w-[60px] h-[40px] px-2 flex justify-center items-center border-r">
                                            {currencyCode}
                                        </div>
                                        <FormInput
                                            placeholder="-"
                                            name="shipping_rate.totalString"
                                            disabled
                                            parentDivClassName="border-none !mb-0 w-full"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="border-t p-4 flex justify-between items-center">
                        <div className="font-bold">
                            TOTAL FOR
                            <div className="inline themes-text-brand-v5 px-1">
                                {`${shippingRatesQty} ${shippingRatesUnit}`}
                            </div>
                            IS
                        </div>
                        <div className="font-bold themes-text-brand-v5">
                            {`${currencyCode}
                            ${
                                shippingRatesTotalString === ''
                                    ? '0'
                                    : shippingRatesTotalString
                            }`}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full themes-bg-white rounded-md px-4 mt-4">
                <div className="flex justify-between items-center relative p-4 pl-0 text-size-M ">
                    <div className="flex">
                        <div className="font-bold mr-2">Service Rates</div>
                        <div
                            className="flex cursor-pointer items-center themes-text-gray-v3"
                            onClick={() => {
                                serviceRatesOverlay.openOverlayHandling()
                            }}
                        >
                            <div className="relative">
                                ({serviceTemplateName})
                                <ServiceRatesOverlay
                                    filterOverlayService={serviceRatesOverlay}
                                    dropdownServiceRates={
                                        dropdownService.serviceRatesData
                                    }
                                    onChange={(data) => {
                                        if (!data) return
                                        const formikValue = formik.values
                                        const chargeCodes = data.charge_codes
                                        const serviceRates: IFormServiceRate[] =
                                            chargeCodes.map((d) => {
                                                const dt: IFormServiceRate = {
                                                    charge_code: {
                                                        code: d.code,
                                                    },
                                                    price: d.price,
                                                    unit: d.unit,
                                                    qty: 0,
                                                    total: 0,
                                                    code: d.code,
                                                }
                                                return dt
                                            })
                                        const value: IFormQuote = {
                                            ...formikValue,
                                            services: serviceRates,
                                            serviceTemplateCode: data.code,
                                            serviceTemplateName: data.name,
                                        }
                                        formik.setValues(value)
                                    }}
                                />
                            </div>
                            <i className="ri-arrow-down-s-line ml-3"></i>
                        </div>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            addNewRow()
                        }}
                    >
                        <i className="ri-add-line pr-1"></i>
                        ADD ITEM
                    </div>
                </div>

                <div className="-mx-4">
                    <table className="table-fixed w-full text-size-S themes-text-gray-v5">
                        <thead>
                            <tr className="border-t text-left border-b themes-border-gray-v3">
                                <th className="pl-4 pr-2 py-2 !w-[50px]">No</th>
                                <th className="p-2 !w-[25%]">Title</th>
                                <th className="p-2 !w-[15%]">Price/Unit</th>
                                <th className="p-2 !w-[20%]">Unit</th>
                                <th className="p-2 !w-[10%]">Qty</th>
                                <th className="p-2 w-auto">Total</th>
                                <th className="p-2 !w-[80px]"></th>
                            </tr>
                        </thead>
                        <tbody>{shippingForm}</tbody>
                    </table>

                    <div className="border-t p-4 flex justify-between items-center">
                        <div className="font-bold">
                            TOTAL FOR
                            <div className="inline themes-text-brand-v5 px-1">
                                {`${totalServiceRatesQty} ${weight_unit}`}
                            </div>
                            IS
                        </div>
                        <div className="font-bold themes-text-brand-v5">
                            {`${currencyCode} ${totalServiceRatesPrice}`}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    const notesComponent = (
        <>
            <FormTextarea name="notes" label="Important Notice" />
        </>
    )

    return (
        <div className="container-global content-full-height mobile:!h-auto sm:!h-auto sm:!h-auto flex">
            <div className="flex flex-grow flex-col">
                <div className="p-3 flex justify-between items-center border-b">
                    <div className="text-size-M font-bold">GET A QUOTE</div>
                    <div className="flex gap-3">
                        <Button
                            onClick={() => {}}
                            icon="ri-file-pdf-2-line"
                            label="PREVIEW PDF"
                        />
                        <Button
                            onClick={() => {
                                formik.submitForm()
                            }}
                            isLoading={isSubmitLoading}
                            icon={
                                isDetailPage
                                    ? 'ri-save-line'
                                    : 'ri-download-2-line'
                            }
                            label={isDetailPage ? 'UPDATE' : 'GENERATE QUOTE'}
                            variant="brand"
                        />
                    </div>
                </div>

                <div className="flex sm:flex-wrap gap-4 p-4 ">
                    <FormikProvider value={formik}>
                        {/* Part 1 */}
                        <div className="flex-init sm:w-full mobile:h-full lg:w-[600px] h-[calc(100vh-260px)] sm:!h-auto mobile:h-auto overflow-auto">
                            <div className="text-size-M font-bold mb-4">
                                Quote Details
                            </div>
                            <div className="flex gap-4 mb-3">
                                <div className="w-[50%]">
                                    <FormDatePicker
                                        name="quote_date"
                                        isRange={false}
                                        label="START DATE"
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <FormDropdown
                                        name="doc_validity_period"
                                        options={periodOptions}
                                        label="Doc Validity Period"
                                        useUppercaseLabel={true}
                                        parentDivClassName="h-[40px]"
                                    />
                                </div>
                            </div>
                            <FormInput
                                name="customer_name"
                                label="Customer Name"
                                placeholder="Input Customer Name"
                                useUppercaseLabel={true}
                            />

                            <div className="text-size-M font-bold mb-4">
                                Shipping Details
                            </div>
                            <FormDropdown
                                name="transport_mode"
                                options={dropdownService.transportModesData}
                                label="Transport Mode"
                                placeholder="Select Transport Mode"
                                useUppercaseLabel={true}
                                parentDivClassName="w-full"
                                additionalOnClick={(data) => {
                                    if (!data) return
                                    const d =
                                        data as IDropdownItem<ITransportMode>
                                    setSelectedTransport(d)
                                }}
                                isSearchable={true}
                            />
                            <FormDropdown
                                name="container_mode"
                                options={dropdownService.containerModeData}
                                label="Container Mode"
                                parentDivClassName="w-full"
                                placeholder="Select Container Mode"
                                isSearchable={true}
                                useUppercaseLabel={true}
                            />
                            <FormDatePicker
                                isRange={false}
                                name="etd"
                                placeholder="ETD"
                                parentDivClassName="mb-4"
                                label="ETD"
                            />
                            <div className="flex gap-4 mb-3">
                                <div className="w-[45%]">
                                    <FormDropdown
                                        name="origin_location.code"
                                        options={dropdownService.unlocodesData}
                                        label="Origin Port"
                                        useUppercaseLabel={true}
                                        placeholder="Origin"
                                        isSearchable={true}
                                        additionalOnClick={(d) => {
                                            const value = (
                                                d as IDropdownItem<IUnlocodes>
                                            ).additionalData?.name
                                            formik.setFieldValue(
                                                'origin_location.name',
                                                value,
                                            )
                                        }}
                                    />
                                </div>
                                <div className="w-[55%]">
                                    <FormInput
                                        name="origin_location.name"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4 mb-3">
                                <div className="w-[45%]">
                                    <FormDropdown
                                        options={dropdownService.unlocodesData}
                                        name="destination_location.code"
                                        label="Destination Port"
                                        useUppercaseLabel={true}
                                        placeholder="Destination"
                                        isSearchable={true}
                                        additionalOnClick={(d) => {
                                            const value = (
                                                d as IDropdownItem<IUnlocodes>
                                            ).additionalData?.name
                                            formik.setFieldValue(
                                                'destination_location.name',
                                                value,
                                            )
                                        }}
                                    />
                                </div>
                                <div className="w-[55%]">
                                    <FormInput
                                        name="destination_location.name"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Part 2 */}

                        <div
                            className={`flex-grow themes-bg-gray-v1 p-4 h-[calc(100vh-260px)] sm:!h-auto  mobile:h-auto overflow-auto w-full`}
                        >
                            <div className="text-size-M font-bold mb-2">
                                Quote Summary
                            </div>
                            <div className="py-2">
                                <Pills<IQuoteSummaryPills>
                                    items={quoteSummaryPillHeaders}
                                    onChange={(e) => {
                                        setPillItems(e)
                                    }}
                                    unActiveVariant="white"
                                />
                            </div>
                            <div className="pt-3">
                                {!isChargeList
                                    ? notesComponent
                                    : chargeListComponent}
                            </div>
                        </div>
                    </FormikProvider>
                </div>

                <div className="py-2 px-4 border-t">
                    <Button onClick={() => navigate(-1)} label="BACK" />
                </div>
            </div>
        </div>
    )
}

export default FormQuote
