import { useModal } from '@components/modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BookingDataDummy } from './bookings.dummy'
import { BookingStatusHeader } from './bookings.static'
import Button from '@components/button/button.component'
import { IButton } from '@components/button/button.interface'
import ConfirmationFormModal from './form-booking/components/confirmation-form-modal.component'
import { IBookings } from './bookings.interface'
import { Toast } from '@components/toast/toast.component'

const useBookings = () => {
    const navigate = useNavigate()

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // state
    const [data, setData] = useState(BookingDataDummy)
    const [dataByStatus, setDataByStatus] = useState(BookingDataDummy)
    const [detailsData, setDetailsData] = useState<IBookings | undefined>()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [tabFilter, setTabFilter] = useState<ITabItem>(BookingStatusHeader[0])
    const [typeModalConfirmation, setTypeModalConfirmation] = useState<
        'cancelBooking' | 'reOpenBooking' | 'closedBooking'
    >('reOpenBooking')
    const [handlingData, setHandlingData] = useState<boolean>(false)

    const confimationFormModal = useModal()

    // get data quotations
    useEffect(() => {
        loadData()
    }, [tabFilter, handlingData])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            setDataByStatus(data.filter((x) => x.status === tabFilter.value))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const bookingStatusHeader: ITabItem[] = [
        {
            label: 'New Bookings',
            totalData: data.filter((x) => x.status === 'new').length,
            key: 'status',
            value: 'new',
            className: 'brand',
        },
        {
            label: 'In Progress',
            totalData: data.filter((x) => x.status === 'inProgress').length,
            key: 'status',
            value: 'inProgress',
            className: 'yellow',
        },
        {
            label: 'Closed',
            totalData: data.filter((x) => x.status === 'closed').length,
            key: 'status',
            value: 'closed',
            className: 'gray',
        },
        {
            label: 'Canceled',
            totalData: data.filter((x) => x.status === 'canceled').length,
            key: 'status',
            value: 'canceled',
            className: 'red',
        },
    ]

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            const searchData = data.filter((booking) =>
                booking.bookingNo.includes(values),
            )
            setData(searchData)
        } else {
            setData(BookingDataDummy)
        }
        setHandlingData(!handlingData)
    }

    const changeStatus = () => {
        const updateData = data.map((item) =>
            item.bookingNo === detailsData?.bookingNo
                ? { ...item, ...detailsData }
                : item,
        )
        setData(updateData)
        setHandlingData(!handlingData)
        confimationFormModal.closeModalHandling()
    }

    const contentConfimationModal = () => {
        let header: JSX.Element = <></>
        let message: JSX.Element = <></>
        let buttonSubmitProperty: IButton = {
            onClick: () => {},
        }

        switch (typeModalConfirmation) {
            case 'cancelBooking':
                header = <>Cancel Booking</>
                message = (
                    <>
                        you want to cancel this {detailsData?.bookingNo}? This
                        action means you will no longer be able process any
                        shipment.
                    </>
                )
                buttonSubmitProperty = {
                    onClick: () => {
                        changeStatus()
                        Toast({
                            header: 'Booking Canceled',
                            message: `Booking ID: ${detailsData?.bookingNo} successfully canceled.`,
                            type: 'success',
                        })
                    },
                    label: 'CANCEL BOOKING',
                    variant: 'red',
                }
                break
            case 'closedBooking':
                header = <>Close Booking</>
                message = (
                    <>
                        Are you sure you want to close this booking{' '}
                        {detailsData?.bookingNo}? This action means the booking
                        will be marked as closed.
                    </>
                )
                buttonSubmitProperty = {
                    onClick: () => {
                        changeStatus()
                        Toast({
                            header: 'Booking Closed',
                            message: `Booking ID: ${detailsData?.bookingNo} successfully closed.`,
                            type: 'success',
                        })
                    },
                    label: 'CLOSE BOOKING',
                    variant: 'brand',
                }
                break
            case 'reOpenBooking':
                header = <>Reopen Booking</>
                message = (
                    <>
                        Are you sure you want to reopen {detailsData?.bookingNo}
                        ? This will restore booking to New Booking.
                    </>
                )
                buttonSubmitProperty = {
                    onClick: () => {
                        changeStatus()
                        Toast({
                            header: 'Booking Reopen',
                            message: `Booking ID: ${detailsData?.bookingNo} successfully reopened.`,
                            type: 'success',
                        })
                    },
                    label: 'REOPEN',
                    variant: 'brand',
                }
                break
        }

        const buttonSubmit = (
            <Button
                onClick={() => {
                    buttonSubmitProperty?.onClick()
                }}
                label={buttonSubmitProperty?.label}
                variant={buttonSubmitProperty?.variant}
                className="w-1/2"
            />
        )

        return {
            header,
            message,
            buttonSubmit,
        }
    }

    // components
    const rowOptionComponent = (
        rowData: IBookings,
        closeHandling: () => void,
    ) => {
        let button1: IButton | null = null
        let button2: IButton | null = null

        switch (tabFilter.value) {
            case 'new':
                button1 = {
                    onClick: () => {
                        navigate('/booking-details')
                    },
                    label: 'Edit Booking',
                    icon: 'ri-edit-line',
                }
                button2 = {
                    onClick: () => {
                        setTypeModalConfirmation('cancelBooking')
                        setDetailsData({ ...rowData, status: 'canceled' })
                        confimationFormModal.openModalHandling()
                        closeHandling()
                    },
                    label: 'Cancel Booking',
                    icon: 'ri-prohibited-line',
                    variant: 'red-inverse',
                }
                break
            case 'inProgress':
                button1 = {
                    onClick: () => {
                        navigate('/booking-details')
                    },
                    label: 'Edit Booking',
                    icon: 'ri-edit-line',
                }
                button2 = {
                    onClick: () => {
                        setTypeModalConfirmation('closedBooking')
                        setDetailsData({ ...rowData, status: 'closed' })
                        confimationFormModal.openModalHandling()
                        closeHandling()
                    },
                    label: 'Close Booking',
                    icon: 'ri-checkbox-circle-line',
                }
                break
            case 'closed':
                button1 = {
                    onClick: () => {
                        navigate('/booking-details')
                    },
                    label: 'Edit Booking',
                    icon: 'ri-edit-line',
                }
                break
            case 'canceled':
                button1 = {
                    onClick: () => {
                        navigate('/booking-details')
                    },
                    label: 'Edit Booking',
                    icon: 'ri-edit-line',
                }
                button2 = {
                    onClick: () => {
                        setTypeModalConfirmation('reOpenBooking')
                        setDetailsData({ ...rowData, status: 'new' })
                        confimationFormModal.openModalHandling()
                        closeHandling()
                    },
                    label: 'Re-Open Booking',
                    icon: 'ri-checkbox-circle-line',
                }
                break
        }
        return (
            <div className="w-full">
                {button1 && (
                    <Button
                        {...button1}
                        className="border-none w-full text-left"
                    />
                )}
                {button2 && (
                    <Button
                        {...button2}
                        className="border-none w-full  text-left"
                    />
                )}
            </div>
        )
    }
    const allModal = (
        <>
            <ConfirmationFormModal
                modalService={confimationFormModal}
                content={contentConfimationModal}
            />
        </>
    )

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        data,
        loading,
        errorMessage,
        allModal,
        dataByStatus,
        bookingStatusHeader,
        handleSearch,
        rowOptionComponent,
        setData,
        setLoading,
        setErrorMessage,
        navigate,
        setTabFilter,
        setTabItems,
    }
}

export default useBookings
