import { IMeta } from '@components/pagination/pagination.interface'
import { IOrganisation } from './organisation.interface'
import {
    IAccounts,
    IAddressList,
    IContactList,
    IEDocumentations,
} from './organisation-details/organisation-details.interface'

export const OrganisationDataDummy: IOrganisation[] = [
    {
        code: 'ORG001',
        fullName: 'Global Tech Innovations',
        category: 'Technology',
        city: 'San Francisco',
        country: 'USA',
        email: 'contact@globaltech.com',
        number: '415-555-0123',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['consignee', 'vip', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG002',
        fullName: 'Eco Solutions Ltd',
        category: 'Environmental',
        city: 'Vancouver',
        country: 'Canada',
        email: 'info@ecosolutions.com',
        number: '604-555-0199',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['consignee', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG003',
        fullName: 'Fast Logistics Co.',
        category: 'Logistics',
        city: 'Tokyo',
        country: 'Japan',
        email: 'support@fastlogistics.jp',
        number: '03-5550-7890',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['carrier'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG004',
        fullName: 'Banking & Finance Corp.',
        category: 'Finance',
        city: 'New York',
        country: 'USA',
        email: 'service@bfcorp.com',
        number: '212-555-2334',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['delivery agent'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG005',
        fullName: 'Pure Water Supplies',
        category: 'Retail',
        city: 'London',
        country: 'UK',
        email: 'contact@purewater.co.uk',
        number: '020-555-7890',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['supplier'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG006',
        fullName: 'Health & Wellness Spa',
        category: 'Healthcare',
        city: 'Bangkok',
        country: 'Thailand',
        email: 'info@healthwellnessspa.com',
        number: '+66-555-9800',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['consignee', 'vip'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG007',
        fullName: 'Artisan Bakery',
        category: 'Food & Beverage',
        city: 'Paris',
        country: 'France',
        email: 'sales@artisanbakery.fr',
        number: '01-555-0101',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['vip', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG008',
        fullName: 'Renewable Energy Systems',
        category: 'Energy',
        city: 'Berlin',
        country: 'Germany',
        email: 'contact@resenergy.de',
        number: '030-555-3030',
        contactType: '',
        status: 'active',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['supplier'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG009',
        fullName: 'Innovative Software Solutions',
        category: 'Technology',
        city: 'San Jose',
        country: 'USA',
        email: 'support@innosoft.com',
        number: '408-555-1234',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['vip'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG010',
        fullName: 'Adventure Sports Ltd',
        category: 'Recreation',
        city: 'Queenstown',
        country: 'New Zealand',
        email: 'bookings@adventuresports.nz',
        number: '03-555-7891',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group A',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['vip', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG011',
        fullName: 'Pro Sports Merchandising',
        category: 'Retail',
        city: 'Chicago',
        country: 'USA',
        email: 'info@prosportsmerch.com',
        number: '312-555-6677',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group B',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['vip', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG012',
        fullName: 'Global Pharmaceuticals',
        category: 'Healthcare',
        city: 'Zurich',
        country: 'Switzerland',
        email: 'contact@globpharma.ch',
        number: '044-555-5555',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group B',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['delivery agent', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG013',
        fullName: 'Cultural Arts Museum',
        category: 'Education',
        city: 'Florence',
        country: 'Italy',
        email: 'info@culturalarts.it',
        number: '055-555-4321',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group C',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['supplier'],
        updatedAt: '12/20/24, 15:00',
    },
    {
        code: 'ORG014',
        fullName: 'High Tech Robotics',
        category: 'Technology',
        city: 'Seoul',
        country: 'South Korea',
        email: 'service@hightechrobotics.kr',
        number: '02-555-7878',
        contactType: '',
        status: 'nonActive',
        userGroup: 'User Group C',
        phoneNumber: '415-555-0987',
        emailAccount: 'national.telcom@gmail.com',
        orgType: ['vip', 'deposit'],
        updatedAt: '12/20/24, 15:00',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

// segment details
export const addressListDummy: IAddressList[] = [
    {
        id: 1,
        addressName: 'Nippon Teuku Umar',
        unloco: 'IDDPS',
        countryOrRegion: 'INDONESIA',
        state: 'BALI',
        city: 'DENPASAR TIMUR',
        postcode: '10110',
        mainAddress: true,
        activeAddress: true,
        address1: 'Jalan Ahmad Dahlan No. 567, Denpasar, Bali',
        address2: '',
        relatedCityOrPort: 'GILIMANUK',
    },
    {
        id: 2,
        addressName: 'Cabang Bandung',
        unloco: 'ID BDO',
        countryOrRegion: 'Indonesia',
        state: 'Jawa Barat',
        city: 'Bandung',
        postcode: '40112',
        mainAddress: false,
        activeAddress: true,
        address1: '',
        address2: '',
        relatedCityOrPort: 'GILIMANUK',
    },
    {
        id: 3,
        addressName: 'Cabang Surabaya',
        unloco: 'ID SUB',
        countryOrRegion: 'Indonesia',
        state: 'Jawa Timur',
        city: 'Surabaya',
        postcode: '60231',
        mainAddress: false,
        activeAddress: true,
        address1: '',
        address2: '',
        relatedCityOrPort: 'GILIMANUK',
    },
    {
        id: 4,
        addressName: 'Kantor Semarang',
        unloco: 'ID SRG',
        countryOrRegion: 'Indonesia',
        state: 'Jawa Tengah',
        city: 'Semarang',
        postcode: '50141',
        mainAddress: false,
        activeAddress: true,
        address1: '',
        address2: '',
        relatedCityOrPort: 'GILIMANUK',
    },
    {
        id: 5,
        addressName: 'Gudang Bali',
        unloco: 'ID DPS',
        countryOrRegion: 'Indonesia',
        state: 'Bali',
        city: 'Denpasar',
        postcode: '80232',
        mainAddress: false,
        activeAddress: false,
        address1: '',
        address2: '',
        relatedCityOrPort: 'GILIMANUK',
    },
]

export const contactListDummy: IContactList[] = [
    {
        id: 1,
        contactName: 'John Doe',
        jobTitle: 'Software Engineer',
        jobCategory: 'Information Technology',
        phoneNumber: '+1234567890',
        email: 'john.doe@example.com',
        active: true,
    },
    {
        id: 2,
        contactName: 'Jane Smith',
        jobTitle: 'Marketing Manager',
        jobCategory: 'Marketing',
        phoneNumber: '+1987654321',
        email: 'jane.smith@example.com',
        active: true,
    },
    {
        id: 3,
        contactName: 'David Lee',
        jobTitle: 'Financial Analyst',
        jobCategory: 'Finance',
        phoneNumber: '+1122334455',
        email: 'david.lee@example.com',
        active: false,
    },
    {
        id: 4,
        contactName: 'Emily Taylor',
        jobTitle: 'HR Specialist',
        jobCategory: 'Human Resources',
        phoneNumber: '+15550998877',
        email: 'emily.taylor@example.com',
        active: true,
    },
    {
        id: 5,
        contactName: 'Michael Johnson',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'michael.johnson@example.com',
        active: false,
    },
]

export const allContactDummy: IContactList[] = [
    {
        id: 1,
        contactName: 'John Doe',
        jobTitle: 'Software Engineer',
        jobCategory: 'Information Technology',
        phoneNumber: '+1234567890',
        email: 'john.doe@example.com',
        active: true,
    },
    {
        id: 2,
        contactName: 'Jane Smith',
        jobTitle: 'Marketing Manager',
        jobCategory: 'Marketing',
        phoneNumber: '+1987654321',
        email: 'jane.smith@example.com',
        active: true,
    },
    {
        id: 3,
        contactName: 'David Lee',
        jobTitle: 'Financial Analyst',
        jobCategory: 'Finance',
        phoneNumber: '+1122334455',
        email: 'david.lee@example.com',
        active: false,
    },
    {
        id: 4,
        contactName: 'Emily Taylor',
        jobTitle: 'HR Specialist',
        jobCategory: 'Human Resources',
        phoneNumber: '+15550998877',
        email: 'emily.taylor@example.com',
        active: true,
    },
    {
        id: 5,
        contactName: 'Michael Johnson',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'michael.johnson@example.com',
        active: false,
    },
    {
        id: 6,
        contactName: 'Michael Simanurung',
        jobTitle: 'Marketing',
        jobCategory: 'Sales',
        phoneNumber: '+144987654312',
        email: 'michael.simanurung@example.com',
        active: false,
    },
    {
        id: 7,
        contactName: 'Michael Simanjuntak',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'michael.simanjuntak@example.com',
        active: false,
    },
    {
        id: 8,
        contactName: 'Xiangling Rianti',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'xiangling.rianti@example.com',
        active: false,
    },
    {
        id: 9,
        contactName: 'Lisa Hassibuan',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'lisa.hassibuan@example.com',
        active: false,
    },
    {
        id: 10,
        contactName: 'Ningguang Napitupulu',
        jobTitle: 'Sales Representative',
        jobCategory: 'Sales',
        phoneNumber: '+14498765432',
        email: 'ningguang.napitupulu@example.com',
        active: false,
    },
]

export const accountsDummy: IAccounts[] = [
    {
        id: 1,
        accountName: 'Savings Account',
        addressList: '123 Main St, Anytown, USA',
        routingNumber: '123456789',
        currency: 'USD',
        defaultAcc: true,
    },
    {
        id: 2,
        accountName: 'Checking Account',
        addressList: '456 Elm St, Othertown, USA',
        routingNumber: '987654321',
        currency: 'USD',
        defaultAcc: true,
    },
    {
        id: 3,
        accountName: 'Investment Account',
        addressList: '789 Oak St, Anotherplace, USA',
        routingNumber: '456123789',
        currency: 'USD',
        defaultAcc: false,
    },
    {
        id: 4,
        accountName: 'Business Account',
        addressList: '101 Pine St, Yetanotherplace, USA',
        routingNumber: '789123456',
        currency: 'USD',
        defaultAcc: false,
    },
    {
        id: 5,
        accountName: 'Credit Card Account',
        addressList: '321 Cedar St, Lastplace, USA',
        routingNumber: '654987321',
        currency: 'USD',
        defaultAcc: false,
    },
]

export const eDocumentationsDummy: IEDocumentations[] = [
    {
        dateAdded: '05-Jan-24 16:59',
        fileName: 'document1.pdf',
        documentType: 'PDF',
        desc: 'Documentation for project A',
        progDescriptions:
            'This document contains detailed instructions for project A.',
        url: 'https://drive.google.com/file/d/1ua5TgM5w--M3edqBo-jKXRfNSeejWa1t/preview',
    },
    {
        dateAdded: '05-Jan-24 16:59',
        fileName: 'invoice1.pdf',
        documentType: 'PDF',
        desc: 'Invoice for Q1',
        progDescriptions:
            'This PDF document contains the invoice summarizing the activities and financial details for Q1.',
        url: 'https://drive.google.com/file/d/1Pnp63YDilQlg2svPhArYb_Rn5N4IuXdr/preview',
    },
    {
        dateAdded: '05-Jan-24 16:59',
        fileName: 'invoice2.pdf',
        documentType: 'PDF',
        desc: 'Invoice for Q2',
        progDescriptions:
            'This PDF document contains the invoice summarizing the activities and financial details for Q2.',
        url: 'https://drive.google.com/file/d/1Pnp63YDilQlg2svPhArYb_Rn5N4IuXdr/preview',
    },
]
