import { IFetchParams, IResponseData } from 'common/common.interface'
import { errorAxiosToast, fetch } from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { ICurrency } from './interface/currency.interface'
import { ITransportMode } from './interface/transport-mode.interface'
import { IShippingRate } from './interface/shipping-rate.interface'
import { IServiceRate } from './interface/service-rate.interface'
import { IUnit } from './interface/unit.interface'
import { IUnlocodes } from './interface/unlocodes.interface'
import { IChargeCode } from './interface/charge-code.interface'

export const fetchContainerTypes = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<any[]>, IFetchParams>({
            endpoint: endpoints.containerTypes,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchCurrency = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<IResponseData<ICurrency[]>, IFetchParams>({
            endpoint: endpoints.currencies,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchTransportMode = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<
            IResponseData<ITransportMode[]>,
            IFetchParams
        >({
            endpoint: endpoints.transportMode,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchShippingRates = async (payload: IFetchParams) => {
    const params: IFetchParams = { sort: 'name', order: 'asc', ...payload }
    try {
        const response = await fetch<
            IResponseData<IShippingRate[]>,
            IFetchParams
        >({
            endpoint: endpoints.shippingRates,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchServiceRates = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<
            IResponseData<IServiceRate[]>,
            IFetchParams
        >({
            endpoint: endpoints.serviceRates,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchWeightUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/weights',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchVolumeUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/volumes',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchlengthUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/lengths',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchChargeCode = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<
            IResponseData<IChargeCode[]>,
            IFetchParams
        >({
            endpoint: endpoints.chargeCodes,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchContainerModes = async (transportCode: string) => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<
            IResponseData<ITransportMode[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.transportMode}/${transportCode}/container_modes`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchUnlocos = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnlocodes[]>, IFetchParams>(
            {
                endpoint: `${endpoints.unlocodes}`,
                params: params,
            },
        )
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
