/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'

const ConfirmationFormModal = ({
    modalService,
    content,
}: {
    modalService: IUseModal
    content: () => {
        header: JSX.Element
        message: JSX.Element
        buttonSubmit: JSX.Element
    }
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3 !p-0">
            <div>
                <div className="p-3 font-bold text-size-L">
                    {content().header}
                </div>
                <div className="border-b"></div>
                <div className="p-3">{content().message}</div>
                <div className="border-b"></div>
                <div className="flex gap-3 p-3">
                    <Button
                        onClick={() => modalService.closeModalHandling()}
                        label="BACK"
                        className="w-1/2"
                    />
                    {content().buttonSubmit}
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmationFormModal
