import { useModal } from '@components/modal/modal.service'

const useUserAccessDetail = () => {
    const modalConfirmationService = useModal()

    function sendEmail() {
        window.location.assign('mailto:alice.johnson@example.com')
    }

    return {
        modalConfirmationService,
        sendEmail,
    }
}

export default useUserAccessDetail
