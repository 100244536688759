import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { BookingDataDummy } from './bookings.dummy'
import { IBookings } from './bookings.interface'
import Checkbox from '@components/checkbox/checkbox.component'

export const BookingStatusHeader: ITabItem[] = [
    {
        label: 'New Bookings',
        totalData: BookingDataDummy.filter((x) => x.status === 'new').length,
        key: 'status',
        value: 'new',
        className: 'brand',
    },
    {
        label: 'In Progress',
        totalData: BookingDataDummy.filter((x) => x.status === 'inProgress')
            .length,
        key: 'status',
        value: 'inProgress',
        className: 'yellow',
    },
    {
        label: 'Closed',
        totalData: BookingDataDummy.filter((x) => x.status === 'closed').length,
        key: 'status',
        value: 'closed',
        className: 'gray',
    },
    {
        label: 'Canceled',
        totalData: BookingDataDummy.filter((x) => x.status === 'canceled')
            .length,
        key: 'status',
        value: 'canceled',
        className: 'red',
    },
]

export const BookingSummaryHeader: ITableColumn<IBookings>[] = [
    {
        label: 'Mode',
        accessor: 'transportMode',
        customBuild: (data) => {
            let iconMode: string
            let labelMode: string
            switch (data) {
                case 'air':
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
                    break
                case 'sea':
                    iconMode = 'ri-ship-line'
                    labelMode = 'SEA'
                    break
                default:
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
            }

            return (
                <div className="flex gap-2">
                    <i className={`${iconMode}`}></i> {labelMode}
                </div>
            )
        },
    },
    {
        label: 'Booking No.',
        accessor: 'bookingNo',
    },
    {
        label: 'Consignee',
        accessor: 'consignee',
    },
    {
        label: 'Shipper',
        accessor: 'shipper',
    },
    {
        label: 'Origin',
        accessor: 'origin',
    },
    {
        label: 'Destination',
        accessor: 'destination',
    },
    {
        label: 'Inc. Pickup',
        accessor: 'incPickup',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.incPickup}
                    disabled
                />
            )
        },
    },
    {
        label: 'Inc. Delivery',
        accessor: 'incDelivery',
        customBuild: (data, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.incDelivery}
                    disabled
                />
            )
        },
    },
    {
        label: 'ETD',
        accessor: 'etd',
    },
    {
        label: 'Created By',
        accessor: 'createdBy',
    },
    {
        label: 'Creation Date',
        accessor: 'creationDate',
    },
    {
        label: 'Last Updated',
        accessor: 'lastUpdated',
    },
]
